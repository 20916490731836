import { InputAdornment, TextField, useTheme } from "@mui/material"
import React, { useState } from "react"
import type { FormDataTypeProps } from "../../../interfaces/formBuilder.interface"
import DynamicIcon from "../lib/DynamicIcon"

const NumberFieldInput: React.FC<FormDataTypeProps> = ({ onDataChange, ...props }: FormDataTypeProps) => {
  const theme = useTheme()

  const [value, setValue] = useState(props.value ?? null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("TextFieldInput -> e.target.value", e.target.value)
    setValue(e.target.value)
    onDataChange(props.id, e.target.value)
  }

  return (
    <TextField
      value={value ?? 0}
      disabled={props.disabled}
      sx={{ bgcolor: theme.palette.background.paper }}
      variant="outlined"
      fullWidth
      label={props.label}
      name={props.name}
      size="small"
      type="number"
      onChange={onChange}
      helperText={props.hintText}
      inputProps={{
        inputMode: "decimal",
        pattern: "[0-9].*",
      }}
      InputProps={{
        readOnly: props.readOnly,
        endAdornment: (
          <InputAdornment position="end">
            <DynamicIcon name={props.type} />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default NumberFieldInput
