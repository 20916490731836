import React, { useState, useEffect, useCallback, useContext } from "react"
import { Button, IconButton, type Theme, useMediaQuery } from "@mui/material"
import { auditService } from "../../../services/audit.service"
import type { IAuditLog } from "../../../interfaces/auditLog.interface"
import AuditList from "./AuditList.component"
import { VscHistory } from "react-icons/vsc"
import LayoutContext from "../../../contexts/layout.context"

interface IProps {
  objectName: string
  objectId: number
}

const AuditButton = ({ objectName, objectId }: IProps) => {
  const [logCount, setLogCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const { openSidebar } = useContext(LayoutContext)

  const fetchLogCount = useCallback(async () => {
    try {
      const count = await auditService.getAuditLogsCount(objectName, objectId)
      setLogCount(count)
    } catch (e) {
      console.error("Error fetching audit log count:", e)
    }
  }, [objectName, objectId])

  useEffect(() => {
    fetchLogCount()
  }, [fetchLogCount])

  const handleOnClick = async () => {
    setIsLoading(true)
    try {
      const logs = await auditService.getAuditLogs(objectName, objectId)
      setIsLoading(false)
      openSidebar(<AuditList logs={logs} />)
    } catch (e) {
      console.error("Error fetching audit logs:", e)
      setIsLoading(false)
    }
  }

  return (
    <>
      {isDesktop ? (
        <Button
          onClick={handleOnClick}
          disabled={logCount === 0 || isLoading}
          sx={{ textTransform: "capitalize", borderRadius: 24, px: 2 }}
          startIcon={<VscHistory />}>
          {isLoading ? "Loading..." : logCount}
        </Button>
      ) : (
        <IconButton color="primary" onClick={handleOnClick} disabled={isLoading}>
          <VscHistory />
        </IconButton>
      )}
    </>
  )
}

export default AuditButton
