import {
  Avatar,
  Stack,
  Typography,
  Button,
  useMediaQuery,
  type Theme,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material"
import React from "react"
import { VscAccount } from "react-icons/vsc"
import { design } from "../../../constants/design.constants"
import { HiOutlineLogout } from "react-icons/hi"
import { useMsal } from "@azure/msal-react"
import { HiMiniCloudArrowDown } from "react-icons/hi2"
import { useSync } from "../../../hooks/useSync"
import { Link } from "react-router-dom"

type Props = {
  firstName: string
  lastName: string
  email: string
  image?: string
}

function Profile({ firstName, lastName, email, image }: Props) {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(menuEl)
  const { instance } = useMsal()

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuEl(null)
  }

  const handleLogout = () => {
    instance.logoutRedirect()
  }

  const renderMenu = () => (
    <Menu
      id="basic-menu"
      anchorEl={menuEl}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      open={open}
      style={{
        width: "100%",
      }}
      slotProps={{
        paper: {
          style: {
            width: design.drawerWidth - 2,
            borderRadius: 12,
            boxShadow: design.shadow,
          },
        },
      }}
      onClose={handleMenuClose}>
      <MenuItem component={Link} to="/settings/database" onClick={handleMenuClose}>
        <Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignContent={"center"} gap={1}>
          <Typography fontSize={14}>Local Database Info</Typography>
        </Stack>
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          handleLogout()
        }}>
        <Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignContent={"center"} gap={1}>
          <Typography fontSize={14}>Sign Out</Typography>
          <HiOutlineLogout size={18} />
        </Stack>
      </MenuItem>
    </Menu>
  )
  if (isDesktop) {
    return (
      <Stack gap={1.5} flexDirection={"row"} alignItems={"center"}>
        <Button
          onClick={handleMenuOpen}
          fullWidth
          sx={{ textTransform: "capitalize", borderRadius: 3, paddingRight: 2 }}>
          <Stack gap={1.5} direction={"row"} alignItems={"center"}>
            {image ? <Avatar style={{ width: 24, height: 24 }} src={image} /> : <VscAccount size={22} />}
            <Stack alignItems={"flex-start"} justifyContent={"flex-start"}>
              <Typography fontWeight={700} sx={{ opacity: 0.9, lineHeight: 1 }} fontSize={"small"}>
                {lastName && lastName !== "" ? `${lastName}, ` : ""} {firstName}
              </Typography>
              <Typography sx={{ opacity: 0.7, textTransform: "lowercase" }} fontSize={10}>
                {email}
              </Typography>
            </Stack>
          </Stack>
        </Button>
        {renderMenu()}
      </Stack>
    )
  }

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <VscAccount />
      </IconButton>
      {renderMenu()}
    </>
  )
}

export default Profile
