import { createTheme, ThemeProvider as MaterialThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import type { PropsWithChildren } from "react"
import { useMemo } from "react"
import type { PaletteMode, ThemeOptions } from "@mui/material"
import { lightColors } from "../constants/design.constants"

function ThemeProvider({ children }: PropsWithChildren) {
  const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
    palette: {
      mode,
      ...lightColors,
    },
  })

  const theme = useMemo(() => createTheme(getDesignTokens("light")), [])

  return (
    <StyledEngineProvider injectFirst>
      <MaterialThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MaterialThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider
