import type { Model } from "@nozbe/watermelondb"
import { isString, isNumber, isDate, pick } from "lodash"

export const exportFile = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getFileNameFromContentDisposition = (contentDisposition: string): string => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(contentDisposition)
  if (matches !== null && matches[1]) {
    return matches[1].replace(/['"]/g, "")
  }
  return "downloaded_file"
}

export const hasNode = (nodes: any, key: string, value: any) => {
  for (const node of nodes) {
    if (node[key] === value) {
      return true
    }
    if (node.children && hasNode(node.children, key, value)) {
      return true
    }
  }
  return false
}

export function assignExcept<T extends object, U extends object>(
  target: T,
  source: U,
  ...excludeProps: (keyof U)[]
): T & Omit<U, (typeof excludeProps)[number]> {
  const filteredEntries = Object.entries(source).filter(([key]) => !excludeProps.includes(key as keyof U))
  return Object.assign(target, Object.fromEntries(filteredEntries))
}

export function updateWatermelonDBObject<T extends Model>(
  wdbObject: T,
  simpleObject: Record<string, any>,
  skipKeys: string[] = [],
): T {
  // Get all property descriptors of the WatermelonDB object
  const descriptors = Object.getOwnPropertyDescriptors(Object.getPrototypeOf(wdbObject))

  // Iterate over the keys of the simple object
  Object.keys(simpleObject).forEach((key) => {
    // Check if the key exists in the WatermelonDB object
    if (skipKeys.includes(key)) {
      return
    }

    if (key in wdbObject) {
      const descriptor = descriptors[key]

      // Check if the property is not readonly
      if (descriptor && descriptor.set && !descriptor.set?.toString().includes("@readonly")) {
        // Update the property if it's not readonly
        ;(wdbObject as any)[key] = simpleObject[key]
      }
    }
  })

  return wdbObject
}

type SimpleValue = string | number | Date

export function filterSimpleProps(
  jsonData: Record<string, any>,
  propsToInclude?: string[],
): Record<string, SimpleValue> {
  const isSimpleValue = (value: any): value is SimpleValue => isString(value) || isNumber(value) || isDate(value)

  const filteredObject = Object.entries(jsonData).reduce(
    (acc, [key, value]) => {
      if (isSimpleValue(value)) {
        acc[key] = value
      }
      return acc
    },
    {} as Record<string, SimpleValue>,
  )

  if (propsToInclude && propsToInclude.length > 0) {
    return pick(filteredObject, propsToInclude)
  }

  return filteredObject
}
