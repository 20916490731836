import type { ImgHTMLAttributes } from "react"
import React, { useState } from "react"
import placeholder from "../../../assets/images/placeholder.png"

interface ImageWithFallbackProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
  fallbackSrc?: string
  alt: string
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({ src, fallbackSrc, alt, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src)

  const handleError = () => {
    if (imgSrc !== fallbackSrc && fallbackSrc) {
      setImgSrc(fallbackSrc)
    } else {
      setImgSrc(placeholder) // Replace with your placeholder
    }
  }

  return <img src={imgSrc} alt={alt} onError={handleError} {...props} />
}

export default ImageWithFallback
