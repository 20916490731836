import React, { type FC, useEffect, useState } from "react"
import type { FormDataObject, FormDataTypeProps } from "../../../interfaces"
import { Box, useTheme } from "@mui/material"
import { useDebounceValue } from "usehooks-ts"
import { HiOutlinePhoto } from "react-icons/hi2"

const ImageItem: FC<FormDataObject & FormDataTypeProps> = ({ onDataChange, options, ...props }: FormDataTypeProps) => {
  const [, setWidth] = useState<number>(200)
  const theme = useTheme()

  useEffect(() => {
    if (options && options["width"]) {
      const _width = options["width"]
      setWidth(Number(_width.value))
    }
  }, [options])

  const [debouncedValue] = useDebounceValue(props.value, 1000)

  useEffect(() => {
    if (debouncedValue) {
      onDataChange(props.id, debouncedValue)
    }
  }, [debouncedValue])

  const renderUploader = () => (
    <Box
      p={4}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      minHeight={200}
      borderRadius={2}
      bgcolor={"white"}
      border={`solid 1px ${theme.palette.divider}`}>
      <HiOutlinePhoto size={64} opacity={0.15} />
    </Box>
  )

  const renderImage = (url?: string) => (
    <Box display={"grid"}>
      {options && (
        <Box
          justifySelf={options["alignment"]?.value ?? "left"}
          bgcolor={theme.palette.background.paper}
          my={1}
          borderRadius={3}
          overflow={"hidden"}
          width={options["width"].value ?? "100%"}
          height={"auto"}
          position={"relative"}>
          <img
            width={"100%"}
            height={"100%"}
            src={url ?? "https://placehold.co/400"}
            alt="test"
            style={{ objectFit: "cover" }}
          />
        </Box>
      )}
    </Box>
  )

  return props.value ? renderImage(props.value) : renderUploader()
}

export default ImageItem
