class GeoLocationService {
  //   async getCurrentLocation(cb: (position: GeolocationPosition) => void) {
  //     if (navigator.geolocation) {
  //       try {
  //         const permission = await navigator.permissions.query({ name: "geolocation" })
  //         if (permission.state !== "denied") {
  //           navigator.geolocation.getCurrentPosition((position) => {
  //             if (position) cb(position)
  //           })
  //         }
  //       } catch (e) {
  //         console.log(e)
  //       }
  //     }
  //   }

  async getCurrentLocation(cb: (position: GeolocationPosition | null) => void) {
    if (navigator.geolocation) {
      try {
        const permission = await navigator.permissions.query({ name: "geolocation" })
        if (permission.state !== "denied") {
          const options = {
            enableHighAccuracy: true,
            timeout: 7000, // ? 5 second timeout
            maximumAge: 0, //? the device cannot use a cached position and must attempt to retrieve the real-time location
          }
          navigator.geolocation.getCurrentPosition(
            (position) => {
              if (position) cb(position)
            },
            (error) => {
              console.error(error)
              cb?.(null)
            },
            options,
          )
        } else {
          return cb?.(null)
        }
      } catch (e) {
        console.log(e)
        cb?.(null)
        return
      }
    }
  }
}

export const geolocationService = new GeoLocationService()
