import { TextField, useTheme } from "@mui/material"
import React from "react"
import type { FormDataTypeProps } from "../../../interfaces/formBuilder.interface"

const TextAreaFieldInput: React.FC<FormDataTypeProps> = ({ onDataChange, ...props }: FormDataTypeProps) => {
  const theme = useTheme()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("TextFieldInput -> e.target.value", e.target.value)
    setValue(e.target.value)
    onDataChange(props.id, e.target.value)
  }

  const [value, setValue] = React.useState(props.value ?? null)

  return (
    <TextField
      disabled={props.disabled}
      sx={{ bgcolor: theme.palette.background.paper }}
      variant="outlined"
      name={props.name}
      fullWidth
      value={value ?? ""}
      multiline
      required={props.required}
      label={props.label}
      onChange={onChange}
      size="small"
      minRows={2}
      helperText={props.hintText}
      InputProps={{
        readOnly: props.readOnly,
      }}
    />
  )
}

export default TextAreaFieldInput
