export const pascalCaseToUnderscore = (str: string) => {
  return (
    str
      // Insert an underscore before any uppercase letter, except the first character
      .replace(/([A-Z])/g, "_$1")
      // Convert the whole string to lowercase
      .toLowerCase()
      // Remove any leading underscore that might have been added if the first character was uppercase
      .replace(/^_/, "")
  )
}

export const truncateString = (input: string, maxLength: number) => {
  const ellipsis = "…"
  if (input.length > maxLength) {
    return input.substring(0, maxLength).trim() + ellipsis
  }
  return input
}

export const isEmailValid = (string: string) => {
  return /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(string)
}

export const removeDomainFromUserName = (username?: string): string | undefined => {
  return username && username.split("@").length > 0 ? username.split("@")[0] : username
}
