import { InputAdornment, useTheme, TextField } from "@mui/material"
import React, { useRef, useState } from "react"
import { IMaskInput } from "react-imask"
import type { FormDataTypeProps } from "../../../interfaces/formBuilder.interface"
import DynamicIcon from "../lib/DynamicIcon"

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  )
})

const PhoneFieldInput: React.FC<FormDataTypeProps> = ({ onDataChange, ...props }: FormDataTypeProps) => {
  const theme = useTheme()

  const [value, setValue] = useState(props.value ?? null)
  const ref = useRef<HTMLDivElement>(null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("TextFieldInput -> e.target.value", e.target.value)
    setValue(e.target.value)
    onDataChange(props.id, e.target.value)
  }

  return (
    <TextField
      required={props.required}
      ref={ref}
      variant="outlined"
      size="small"
      fullWidth
      disabled={props.disabled}
      label={props.label}
      name={props.name}
      id={props.id}
      onChange={onChange}
      value={value}
      autoComplete="off"
      placeholder="(###)-###-####"
      sx={{ bgcolor: theme.palette.background.paper }}
      InputProps={{
        readOnly: props.readOnly,
        inputComponent: TextMaskCustom as any,
        endAdornment: (
          <InputAdornment position="end">
            <DynamicIcon name={props.type} />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default PhoneFieldInput
