import React, { useContext, useEffect } from "react"
import { Outlet } from "react-router"
import type { Theme } from "@mui/material"
import { Box, Drawer, Stack, useMediaQuery } from "@mui/material"

import { design } from "../constants/design.constants"
import { Sidebar, StatusBar, Topbar } from "../components"
import { useWindowSize } from "usehooks-ts"
import LayoutContext from "../contexts/layout.context"

function DashboardLayout() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"))
  const { layoutState, closeSidebar, closeActionSheet, toggleNavigation } = useContext(LayoutContext)
  const { width, height } = useWindowSize()

  useEffect(() => {
    console.log("DashboardLayout -> layoutState.navigationMode", layoutState.navigationMode)
    console.log("DashboardLayout -> isTablet", isTablet)
    if (isTablet) {
      if (layoutState.navigationMode === "full") {
        toggleNavigation()
      }
    }
  }, [])

  return (
    <Stack
      justifyContent={isDesktop ? "left" : "space-around"}
      flexDirection={isDesktop ? "row" : "column"}
      position={"relative"}>
      {isDesktop ? <Sidebar /> : <Topbar />}

      <Stack
        flex={1}
        marginLeft={
          isDesktop
            ? layoutState.navigationMode === "full"
              ? `${design.drawerWidth}px`
              : `${design.drawerMinimizedWidth}px`
            : 0
        }
        sx={{
          transitionProperty: "all",
          transitionDuration: "0.1s",
          transitionTimingFunction: "ease-in-out",
        }}
        position={"relative"}>
        <Stack
          sx={{
            transitionProperty: "all",
            transitionDuration: "0.1s",
            transitionTimingFunction: "ease-in-out",
          }}
          marginRight={
            layoutState.navigationMode === "minimized" && layoutState.sidebarOpen ? `${design.rightDrawerWidth}px` : 0
          }>
          <StatusBar />
          <Outlet />
        </Stack>
        <Drawer
          onClose={closeSidebar}
          hideBackdrop
          variant="persistent"
          elevation={0}
          PaperProps={{
            style: {
              zIndex: 999,
              background: "white",
              borderTopLeftRadius: layoutState.sidebarMode === "half" ? 10 : 0,
              borderTopRightRadius: layoutState.sidebarMode === "half" ? 10 : 0,
              boxShadow: layoutState.navigationMode === "minimized" ? "none" : "rgb(0 0 0 / 22%) 0px 0px 10px -3px",
              width: isDesktop ? `${design.rightDrawerWidth}px` : "100%",
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
          open={layoutState.sidebarOpen}
          anchor={isDesktop ? "right" : "bottom"}>
          <>
            {layoutState.sidebarMode === "full" && (
              <Box height={height} width={"100%"}>
                <>{layoutState.sidebar}</>
              </Box>
            )}

            {layoutState.sidebarMode === "half" && (
              <Box height={!isDesktop ? height / 1.5 : 320} width={"100%"}>
                <>{layoutState.sidebar}</>
              </Box>
            )}
          </>
        </Drawer>
        <Drawer
          onClose={closeActionSheet}
          elevation={0}
          PaperProps={{
            style: {
              zIndex: 9999,
              background: "transparent",
              boxShadow: "none",
              width: isDesktop ? width / 3 : "100%",
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
          open={layoutState.sheetOpen}
          anchor={"bottom"}>
          <>
            <Box minHeight={height / 2} display={"flex"} alignItems={"flex-end"} width={"100%"}>
              <>{layoutState.actionSheet}</>
            </Box>
          </>
        </Drawer>
      </Stack>
    </Stack>
  )
}

export default DashboardLayout
