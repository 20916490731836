// src/model/Upload.js

import { Model } from "@nozbe/watermelondb"
import { field, date, json } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"

export default class Upload extends Model {
  static table = TableName.Upload

  @field("blob_name") blobName
  @field("file_name") fileName
  @field("file_size") fileSize
  @json("uploaded_blocks", (json) => json) uploadedBlocks // Stored as JSON array
  @date("created_at") createdAt
  @date("updated_at") updatedAt
}
