import { BASE_API_URL } from "../constants/commonStrings.constant"
import type { IProject, IUser } from "../interfaces"
import { apiService } from "./api.service"

class ProjectService {
  async getAll(worldId?: number): Promise<IProject[]> {
    let url = BASE_API_URL + "/project"
    if (worldId) {
      url += `?worldId=${worldId}`
    }
    const response = await apiService.get(url, { withCredentials: false })
    const { data } = response
    return data
  }

  async getUsers(projectId?: number): Promise<IUser[]> {
    const url = BASE_API_URL + "/project/users"
    const response = await apiService.get(url, { withCredentials: false, params: { projectId } })
    const { data }: { data: IUser[] } = response
    return data
  }

  async addUser(user: IUser, projectId: Number): Promise<IUser> {
    const url = BASE_API_URL + "/project/users"
    const response = await apiService.post(url, { withCredentials: false, params: { projectId, user } })
    const { data }: { data: IUser } = response
    return data
  }

  async deleteUser(user: IUser, projectId: Number): Promise<IUser> {
    const url = BASE_API_URL + "/project/users"
    const response = await apiService.delete(url, { withCredentials: false, params: { projectId, user } })
    const { data }: { data: IUser } = response
    return data
  }

  async add(payload: IProject): Promise<IProject> {
    const url = BASE_API_URL + "/project"

    const response = await apiService.post(url, payload, { withCredentials: false })

    const { data } = response
    return data
  }

  async update(payload: IProject): Promise<IProject> {
    const url = BASE_API_URL + "/project"

    const response = await apiService.put(url, payload, { withCredentials: false })

    const { data } = response
    return data
  }
}

export const projectService = new ProjectService()
