import React, { useState, useCallback } from "react"
import type { AlertColor } from "@mui/material"
import { Snackbar, Alert } from "@mui/material"

interface NotificationOptions {
  message: string
  severity?: AlertColor
  duration?: number
}

const useNotification = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>("")
  const [severity, setSeverity] = useState<AlertColor>("info")
  const [duration, setDuration] = useState<number>(6000)

  const showNotification = useCallback(({ message, severity = "info", duration = 6000 }: NotificationOptions): void => {
    setMessage(message)
    setSeverity(severity)
    setDuration(duration)
    setOpen(true)
  }, [])

  const hideNotification = useCallback((event?: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }, [])

  const NotificationComponent: React.FC = () => (
    <Snackbar open={open} autoHideDuration={duration} onClose={hideNotification}>
      <Alert onClose={hideNotification} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  )

  return { showNotification, NotificationComponent }
}

export default useNotification
