import type { AxiosError, AxiosResponse } from "axios"
import axios from "axios"
import { msalInstance } from "../index"
import { loginRequest } from "../constants/authConfig.constant"
import type { IUser } from "@utilisourcepackagelibdev/utilisourcepackagelib"

declare module "axios" {
  export interface AxiosRequestConfig {
    onError?: Function
    onSuccess?: Function
    options?: {
      ignoreAbortController?: boolean
    }
    id?: string
  }
}

const axiosInstance = axios.create()
axiosInstance.defaults.headers.common["Content-Type"] = "application/json"
axiosInstance.defaults.withCredentials = true

//? Contains all the request ids and abort controllers related with them
const requestAbortControllers: any = {}

const abortRequest = (requestId: string) => {
  if (requestAbortControllers[requestId]) {
    requestAbortControllers[requestId]?.abort()
  }
}

export const getAuthToken = async () => {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    //throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.")
    return { token: "" }
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  })

  const user: IUser = {
    externalId: response.account.tenantId,
    firstName: response.account.name?.split(" ")[0],
    lastName: response.account.name?.split(" ")[1] ?? "",
    userName: response.account.username,
    email: response.account.username,
    permissions: [],
  }
  const token = response.accessToken
  return { token, user }
}

//? Can pass clearAuth to this function and automatically call it in the interceptors.response error to log the user out and clear the global auth state
//? Add this to app.tsx or header or something that could pass in the clearAuth in the future
export const setupInterceptors = () => {
  axiosInstance.interceptors.request.use(
    async (config: any) => {
      const requestAbortId = `controller/${config.url}_${config.id}`
      if (!config?.options?.ignoreAbortController && requestAbortControllers[requestAbortId]) {
        //? This will abort the request if the same request is called more than once.
        abortRequest(requestAbortId)
      }
      if (!config.headers) {
        config.headers = {}
      }
      const { token } = await getAuthToken()
      config.headers["Authorization"] = `Bearer ${token}`
      requestAbortControllers[requestAbortId] = new AbortController()
      if (requestAbortControllers[requestAbortId] !== undefined) {
        config.signal = requestAbortControllers[requestAbortId]?.signal
      }
      return config
    },
    async (error: AxiosError) => {
      //? Add custom error logic here
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    (res: AxiosResponse) => {
      if (res?.config?.onSuccess) {
        return res.config.onSuccess(res)
      }
      return res
    },
    async (err: AxiosError) => {
      const originalConfig = err.config as any

      // If the error is due to an expired token (401 error)
      if (err.response?.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          // Attempt to get a new token
          const { token } = await getAuthToken()

          // Update the Authorization header with the new token
          axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`

          // Retry the original request with the new token
          return axiosInstance(originalConfig)
        } catch (refreshError) {
          // If refreshing the token fails, you might want to log out the user
          // or handle the error in some other way
          console.error("Failed to refresh token:", refreshError)

          // You can add a call to your logout function here if needed
          // Example: await logout();

          return Promise.reject(refreshError)
        }
      }

      // For other types of errors, or if token refresh fails
      if (err?.config?.onError) {
        return err.config.onError(err)
      }
      return Promise.reject(err)
    },
  )
}

export const apiService = axiosInstance
