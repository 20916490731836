import type { ReactNode } from "react"
import React, { useCallback, useEffect, useState } from "react"
import type { FormDataObject } from "../../../interfaces"

interface IProps {
  item: FormDataObject
  items: FormDataObject[]
  children: ReactNode
}

function ConditionalItem(props: IProps) {
  const { item, items, children } = props
  const hasRules = item.rules && item.rules.length > 0
  const [dependantInputs, setDependantInputs] = useState<string[]>([])

  const renderConditions = useCallback(() => {
    if (item.rules) {
      const inputWithRules = item.rules.map((rule) => {
        const selectedInput = items.find((i) => i.id === rule.inputId)
        if (selectedInput) {
          if (rule.behavior === "reload") {
            const event = new CustomEvent("reloadData", { detail: { inputId: selectedInput.id } })
            window.dispatchEvent(event)
            return "block"
          }
          if (rule.behavior === "visibility") {
            if (rule.action === "visible" && operator(rule.condition, selectedInput.value, rule.value)) {
              return "block"
            } else if (rule.action === "hidden" && operator(rule.condition, selectedInput.value, rule.value)) {
              return "none"
            } else if (rule.action === "hidden") {
              return "block"
            }
            return "none"
          }
        }
        return "block"
      })
      setDependantInputs([...inputWithRules])
    }
  }, [item, items])

  useEffect(() => {
    renderConditions()
  }, [renderConditions])

  const operator = (type: "equal" | "not equal" | "contains" | "not empty", a: string, b: string): boolean => {
    switch (type) {
      case "equal":
        return a === b
      case "contains":
        return a.includes(b)
      case "not equal":
        return a !== b
      case "not empty":
        return typeof a !== "undefined" && a.length > 0
    }
  }

  if (!hasRules || dependantInputs.filter((input) => input === "block").length > 0) {
    return children
  }
  return <div style={{ display: "none" }}>{children}</div>
}

export default ConditionalItem
