import { useState, useCallback, useRef, useContext, useEffect } from "react"
import { useDatabase } from "@nozbe/watermelondb/hooks"
import { Q } from "@nozbe/watermelondb"
import type Attachment from "../../model/Attachment"
import type Upload from "../../model/Upload"
import { TableName } from "../../model/schema"
import useObservable from "../useObservable"
import type { BlockBlobClient } from "@azure/storage-blob"
import { BlobServiceClient } from "@azure/storage-blob"
import { storageService } from "../../services/storage.service"
import SettingsContext from "../../contexts/settings.context"
import { deleteFileData, getFileData, saveFileData } from "../../utils/fileCache.util"
import { updateWatermelonDBObject } from "../../utils/data.util"
import { resizeImage } from "../../utils/image.util"
import useNotification from "../useNotification"
import { BLOB_CHUNK_SIZE } from "../../constants/commonStrings.constant"

interface UploadState {
  blobName: string
  fileName: string
  fileSize: number
  uploadedBlocks: string[]
  createdAt: number
  updatedAt: number
}

function dataURLtoBlob(dataurl: string) {
  const arr = dataurl.split(",")
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

// Function to extract expiry date from SAS token
function getSasTokenExpiry(sasToken: string) {
  if (sasToken.startsWith("?")) {
    sasToken = sasToken.substring(1)
  }

  const params = new URLSearchParams(sasToken)
  const se = params.get("se")

  if (se) {
    const decodedSe = decodeURIComponent(se)
    const expiryDate = new Date(decodedSe)
    return expiryDate
  }

  return null
}

export function useAttachments() {
  const database = useDatabase()
  const [error, setError] = useState<any | undefined>()
  const blobServiceClient = useRef<BlobServiceClient | null>(null)
  const { settingsState, setSasToken, updateIDBUsage } = useContext(SettingsContext)
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [uploadStates, setUploadStates] = useState<UploadState[]>([])
  const [isInitialized, setIsInitialized] = useState(false)
  const { showNotification, NotificationComponent } = useNotification()

  useEffect(() => {
    const initializeUploadStates = () => {
      const savedStates = localStorage.getItem("uploads")
      if (savedStates) {
        setUploadStates(JSON.parse(savedStates))
      }
      setIsInitialized(true)
    }

    initializeUploadStates()
  }, [])

  useEffect(() => {
    // Save upload states to localStorage whenever they change
    if (isInitialized) {
      localStorage.setItem("uploads", JSON.stringify(uploadStates))
    }
  }, [uploadStates, isInitialized])

  const initializeBlobServiceClient = useCallback(async () => {
    if (!blobServiceClient.current) {
      let sasToken = settingsState.sasToken
      let sasTokenExpiry = settingsState.sasTokenExpiry ? new Date(settingsState.sasTokenExpiry) : new Date()

      if (!sasToken || !sasTokenExpiry || new Date() >= sasTokenExpiry) {
        try {
          sasToken = await storageService.getAzureSAS("", true)
          console.log("Retrieved SAS token:", sasToken)
          sasTokenExpiry = getSasTokenExpiry(sasToken) || new Date()
          setSasToken(sasToken, sasTokenExpiry) // Update SAS token in settings
        } catch (err) {
          console.warn("Failed to retrieve SAS token. Device might be offline.", err)
          return // Exit if we can't get a SAS token
        }
      }

      const client = new BlobServiceClient(
        `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net?${sasToken}`,
      )
      blobServiceClient.current = client
    }
  }, [settingsState.sasToken, setSasToken])

  async function uploadFileInBlocks(
    blockBlobClient: BlockBlobClient,
    fileBlob: Blob,
    blobName: string,
    attachmentData: Attachment,
  ) {
    const blockSize = BLOB_CHUNK_SIZE
    const totalSize = fileBlob.size
    const totalBlocks = Math.ceil(totalSize / blockSize)

    // Find existing upload state or create a new one
    let uploadState = uploadStates.find((state) => state.blobName === blobName)
    if (!uploadState) {
      uploadState = {
        blobName,
        fileName: attachmentData.name ?? "Uploaded Image",
        fileSize: totalSize,
        uploadedBlocks: [],
        createdAt: Date.now(),
        updatedAt: Date.now(),
      }
      setUploadStates((prevStates) => [...prevStates, uploadState!])
    }

    // Save the file data to IndexedDB if not already saved
    const cachedFileBlob = await getFileData(blobName)
    if (!cachedFileBlob) {
      try {
        await saveFileData(blobName, fileBlob)

        updateIDBUsage()
      } catch (error) {
        console.error(`Failed to save file data for ${blobName}:`, error)
        throw error
      }
    } else {
      fileBlob = cachedFileBlob
    }

    const blockIds = []

    for (let i = 0; i < totalBlocks; i++) {
      const blockId = btoa(`block-${i.toString().padStart(6, "0")}`)
      blockIds.push(blockId)

      // Skip already uploaded blocks
      if (uploadState.uploadedBlocks.includes(blockId)) {
        continue
      }

      const start = i * blockSize
      const end = Math.min(start + blockSize, totalSize)
      const blockData = fileBlob.slice(start, end)

      try {
        // Upload the block
        await blockBlobClient.stageBlock(blockId, blockData, end - start)

        // Update the upload state
        uploadState.uploadedBlocks.push(blockId)
        uploadState.updatedAt = Date.now()
        setUploadStates((prevStates) => prevStates.map((state) => (state.blobName === blobName ? uploadState! : state)))

        console.log(`Uploaded block ${i + 1}/${totalBlocks}`)
      } catch (error) {
        console.error(`Error uploading block ${i + 1}:`, error)
        throw error
      }
    }

    // Commit the blocks
    await blockBlobClient.commitBlockList(blockIds)

    // Set metadata
    const metadata = {
      FileName: attachmentData.name ?? "Uploaded Image",
      ContentType: fileBlob.type,
      // Add any other metadata you want to store
    }
    await blockBlobClient.setMetadata(metadata)

    // Set HTTP headers
    const blobHttpHeaders = {
      blobContentType: fileBlob.type,
      blobContentDisposition: `attachment; filename="${attachmentData.name ?? "Uploaded Image"}"`,
    }
    await blockBlobClient.setHTTPHeaders(blobHttpHeaders)

    // Remove the upload state
    setUploadStates((prevStates) => prevStates.filter((state) => state.blobName !== blobName))

    // Remove the file data from IndexedDB
    try {
      await deleteFileData(blobName)
    } catch (error) {
      console.error(`Failed to delete file data for ${blobName}:`, error)
    }

    updateIDBUsage()

    console.log("Upload complete")
  }

  const resumeIncompleteUploads = useCallback(async () => {
    if (!isOnline || !isInitialized) {
      console.log("Device is offline or not initialized. Cannot resume uploads.")
      return
    }

    try {
      await initializeBlobServiceClient()
    } catch (err) {
      console.warn("Device is offline or failed to initialize blobServiceClient:", err)
    }

    for (let i = 0; i < uploadStates.length; i++) {
      const uploadState = uploadStates[i]
      console.log(`Processing upload ${i + 1}/${uploadStates.length}, Blob: ${uploadState.blobName}`)
      try {
        const blobName = uploadState.blobName
        const containerClient = blobServiceClient.current?.getContainerClient(
          process.env.REACT_APP_AZURE_STORAGE_CONTAINER ?? "",
        )
        const blockBlobClient = containerClient?.getBlockBlobClient(blobName)

        // Retrieve the file data from IndexedDB
        const fileBlob = await getFileData(blobName)

        if (!fileBlob) {
          console.error(`File data not found for ${blobName}`)
          // Cannot resume upload without file data; remove upload record
          setUploadStates((prevStates) => prevStates.filter((state) => state.blobName !== blobName))
          continue
        }

        // Resume uploading the remaining blocks
        if (!blockBlobClient) {
          throw new Error("Block blob client not found")
        }

        await uploadFileInBlocks(blockBlobClient, fileBlob, uploadState.blobName, {
          name: uploadState.fileName,
        } as Attachment)
        console.log(`Successfully resumed and completed upload for ${blobName}`)
      } catch (error) {
        showNotification({
          message: `Error resuming upload for ${uploadState.blobName}`,
          severity: "error",
        })
        console.error(`Error resuming upload for ${uploadState.blobName}:`, error)
      }
    }
  }, [uploadStates, blobServiceClient, initializeBlobServiceClient, isOnline, isInitialized])

  // Effect to handle online/offline status
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true)
    }

    const handleOffline = () => {
      setIsOnline(false)
    }

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [])

  // New effect to handle resuming uploads when initialized and online
  useEffect(() => {
    if (isInitialized && isOnline) {
      console.log("Initialized and online, resuming uploads")
      resumeIncompleteUploads()
    }
  }, [isInitialized, isOnline])

  const addAttachment = useCallback(
    async (newAttachmentData: Attachment) => {
      try {
        await initializeBlobServiceClient()
      } catch (err) {
        console.warn("Device is offline or failed to initialize blobServiceClient:", err)
      }

      try {
        // Always store the attachment metadata in WatermelonDB
        const { data, ...attachmentDataWithoutData } = newAttachmentData

        if (!data) {
          throw new Error("No data found in the attachment")
        }

        // Generate thumbnail
        const thumbnailDataUrl = await resizeImage(data, 300, 300)

        const fileBlob = dataURLtoBlob(data)
        const thumbnailBlob = dataURLtoBlob(thumbnailDataUrl)
        const totalSize = fileBlob.size

        const blobName = `Documents/${newAttachmentData.path}/${newAttachmentData.name}`
        const thumbnailBlobName = `Documents/${newAttachmentData.path}/${newAttachmentData.name}_thumbnail.jpg`

        await database.write(async () => {
          await database.collections.get<Attachment>(TableName.Attachment).create((attachment) => {
            updateWatermelonDBObject(attachment, attachmentDataWithoutData)
          })
        })

        // Create upload states for main file and thumbnail
        const newUploadStates: UploadState[] = [
          {
            blobName,
            fileName: attachmentDataWithoutData.name ?? "Uploaded Image",
            fileSize: totalSize,
            uploadedBlocks: [],
            createdAt: Date.now(),
            updatedAt: Date.now(),
          },
          {
            blobName: thumbnailBlobName,
            fileName: `${attachmentDataWithoutData.name}_thumbnail.jpg`,
            fileSize: thumbnailBlob.size,
            uploadedBlocks: [],
            createdAt: Date.now(),
            updatedAt: Date.now(),
          },
        ]
        setUploadStates((prevStates) => [...prevStates, ...newUploadStates])

        // Save file data to IndexedDB using the attachment ID
        await saveFileData(blobName, fileBlob)
        await saveFileData(thumbnailBlobName, thumbnailBlob)

        updateIDBUsage()
        // If online and blobServiceClient is initialized, attempt to upload immediately
        if (isOnline && blobServiceClient.current) {
          await resumeIncompleteUploads()
        } else {
          console.warn("Attachment saved for later upload when online.")
        }

        return newAttachmentData
      } catch (err) {
        setError(err)
        console.error("Error saving attachment:", err)
      }
    },
    [database, initializeBlobServiceClient, isOnline, isInitialized],
  )

  const addAttachmentToBatch = useCallback(
    async (newAttachmentData: Attachment) => {
      try {
        // Always store the attachment metadata in WatermelonDB
        const { data, ...attachmentDataWithoutData } = newAttachmentData

        if (!data) {
          throw new Error("No data found in the attachment")
        }

        // Generate thumbnail
        const thumbnailDataUrl = await resizeImage(data, 300, 300)

        const fileBlob = dataURLtoBlob(data)
        const thumbnailBlob = dataURLtoBlob(thumbnailDataUrl)
        const totalSize = fileBlob.size

        const blobName = `Documents/${newAttachmentData.path}/${newAttachmentData.name}`
        const thumbnailBlobName = `Documents/${newAttachmentData.path}/${newAttachmentData.name}_thumbnail.jpg`

        await database.write(async () => {
          await database.collections.get<Attachment>(TableName.Attachment).create((attachment) => {
            updateWatermelonDBObject(attachment, attachmentDataWithoutData)
          })
        })

        // Create upload states for main file and thumbnail
        const newUploadStates: UploadState[] = [
          {
            blobName,
            fileName: attachmentDataWithoutData.name ?? "Uploaded Image",
            fileSize: totalSize,
            uploadedBlocks: [],
            createdAt: Date.now(),
            updatedAt: Date.now(),
          },
          {
            blobName: thumbnailBlobName,
            fileName: `${attachmentDataWithoutData.name}_thumbnail.jpg`,
            fileSize: thumbnailBlob.size,
            uploadedBlocks: [],
            createdAt: Date.now(),
            updatedAt: Date.now(),
          },
        ]
        setUploadStates((prevStates) => [...prevStates, ...newUploadStates])

        // Save file data to IndexedDB using the attachment ID
        await saveFileData(blobName, fileBlob)
        await saveFileData(thumbnailBlobName, thumbnailBlob)

        updateIDBUsage()

        return newAttachmentData
      } catch (err) {
        setError(err)
        console.error("Error adding attachment to batch:", err)
      }
    },
    [updateIDBUsage],
  )

  const processBatchUpload = useCallback(async () => {
    try {
      await initializeBlobServiceClient()
    } catch (err) {
      console.warn("Device is offline or failed to initialize blobServiceClient:", err)
      return
    }

    // Attempt to upload if online
    if (isOnline && blobServiceClient.current) {
      await resumeIncompleteUploads()
    } else {
      console.warn("Batch saved for later upload when online.")
    }
  }, [initializeBlobServiceClient, isOnline])

  // Function to update an existing attachment
  const updateAttachment = useCallback(
    async (updatedAttachmentData: Attachment) => {
      try {
        await database.write(async () => {
          const attachment = await database.collections.get(TableName.Attachment).find(updatedAttachmentData.id)
          await attachment.update((attachment) => {
            Object.assign(attachment, updatedAttachmentData)
          })
        })
      } catch (err) {
        setError(err)
        console.error("Error updating attachment:", err)
      }
    },
    [database],
  )

  // Function to delete an attachment
  const deleteAttachment = useCallback(
    async (id: string): Promise<string | undefined> => {
      try {
        await database.write(async () => {
          const attachment = await database.collections.get(TableName.Attachment).find(id)
          await attachment.markAsDeleted()
        })
        return "Deleted Successfully"
      } catch (err) {
        setError(err)
        console.error("Error deleting attachment:", err)
      }
    },
    [database],
  )

  return {
    addAttachment,
    updateAttachment,
    deleteAttachment,
    resumeIncompleteUploads,
    addAttachmentToBatch,
    processBatchUpload,
    error,
    isOnline,
  }
}
