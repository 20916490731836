import type { OverridableStringUnion } from "@mui/types"
import type { BadgePropsColorOverrides } from "@mui/material"
import { green, grey, red } from "@mui/material/colors"
import { BASE_API_URL } from "../constants/commonStrings.constant"
import type { ISurvey, ISurveyAttachment, ISurveyFile, ISurveyStyled, IFeature, IFormAttachment } from "../interfaces"
import { apiService } from "./api.service"
import type { ISurveyFilterStatus } from "../interfaces/survey.interface"
import dayjs from "dayjs"

class SurveyService {
  async getSurveys(projectId: number): Promise<ISurvey[]> {
    const url = BASE_API_URL + `/survey`
    const response = await apiService({
      url,
      method: "GET",
      params: {
        projectId,
      },
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async search(
    time: string,
    projectId: number,
    searchText?: string,
    formId?: number,
    status?: ISurveyFilterStatus,
  ): Promise<ISurvey[]> {
    const url = BASE_API_URL + `/survey/search`

    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        time: time ?? "all",
        formId,
        searchText,
        projectId,
        status,
        offset: dayjs().utcOffset(),
      },
    })
    const { data } = response
    return data
  }

  async getSurveysStyled(projectId: number, surveyId?: number): Promise<ISurveyStyled[]> {
    const url = BASE_API_URL + `/survey/survey-styled`
    const response = await apiService({
      url,
      method: "GET",
      params: {
        projectId,
        surveyId,
      },
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async getSurveyFeatures(surveyId: number): Promise<IFeature[]> {
    const url = BASE_API_URL + `/survey/features/${surveyId.toString()}`

    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })

    const { data } = response
    return data
  }

  async getSurveyAttachments(surveyId: string): Promise<ISurveyAttachment[]> {
    const url = BASE_API_URL + `/survey/images/${surveyId}`

    // TODO: Add method to get attachments

    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })

    const { data } = response
    return data
  }

  async deleteImage(path: string): Promise<string> {
    const url = BASE_API_URL + "/survey/delete-image"
    const response = await apiService.delete(url, {
      params: {
        path,
      },
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async uploadImage(payload: IFormAttachment, formId?: string): Promise<IFormAttachment> {
    const url = BASE_API_URL + "/survey/upload"

    const response = await apiService.post(
      url,
      {
        ...payload,
        formId,
      },
      {
        withCredentials: false,
      },
    )

    const { data } = response
    return {
      ...payload,
      name: payload.name,
      data: undefined,
      url: data,
    }
  }

  async getSurveyImages(surveyId: string, fieldId: string): Promise<ISurveyFile[]> {
    const url = BASE_API_URL + `/survey/images/${surveyId}/${fieldId}`

    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })

    const { data } = response
    return data
  }

  async uploadAttachments(payload: ISurveyFile[]) {
    const url = BASE_API_URL + "/survey/upload-images"

    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })

    const { data } = response
    return data
  }

  async uploadImages(payload: ISurveyFile[]) {
    const url = BASE_API_URL + "/survey/upload-images"

    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })

    const { data } = response
    return data
  }

  // async deleteImage(surveyFileId: string): Promise<string | null> {
  //   const url = BASE_API_URL + "/survey/delete-image/" + surveyFileId
  //   const response = await apiService({
  //     url,
  //     method: "DELETE",
  //     withCredentials: false,
  //   })
  //   if (response.status === 200) {
  //     return "deleted"
  //   }
  //   return null
  // }

  async getSurvey(id: number): Promise<ISurvey> {
    const url = BASE_API_URL + "/survey/" + id.toString()
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data }: { data: ISurvey } = response
    return data
  }

  async addSurvey(payload: ISurvey): Promise<ISurvey> {
    const url = BASE_API_URL + "/survey"
    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })

    const { data }: { data: ISurvey } = response

    return data
  }

  async addSurveyFeature(payload: IFeature): Promise<IFeature> {
    const url = BASE_API_URL + "/surveyfeature"
    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })

    const { data }: { data: IFeature } = response

    return data
  }

  async updateSurvey(survey: ISurvey): Promise<ISurvey> {
    const url = BASE_API_URL + "/survey"

    const response = await apiService.put(url, survey, {
      withCredentials: false,
    })
    const { data } = response

    return data
  }

  async updateSurveyStatus(surveyId: number, status?: string): Promise<any> {
    const url = BASE_API_URL + "/survey/update-status"

    const response = await apiService.put(
      url,
      {
        surveyId,
        status,
      },
      {
        withCredentials: false,
      },
    )
    const { data } = response

    return data
  }

  async deleteSurvey(id: number): Promise<string> {
    const url = BASE_API_URL + `/survey/${id}`

    const response = await apiService.delete(url, {
      withCredentials: false,
    })
    const { statusText } = response
    return statusText
  }

  getStatusColor(status: string | undefined, active: boolean | undefined): string {
    if (active) {
      return "#4fc3f7"
    }
    switch (status) {
      case "waiting":
        return "#aaa"
      case "started":
        return "#81c784"
      case "paused":
        return "#ffb74d"
      case "abandoned":
        return "#e57373"
      case "completed":
        return "#888"
      default:
        return "grey"
    }
  }

  getStatusThemeColor(
    status: string | undefined,
    active: boolean | undefined,
  ): OverridableStringUnion<
    "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning",
    BadgePropsColorOverrides
  > {
    if (active) {
      return "info"
    }
    switch (status) {
      case "waiting":
        return "default"
      case "started":
        return "success"
      case "paused":
        return "warning"
      case "abandoned":
        return "error"
      case "completed":
        return "secondary"
      default:
        return "default"
    }
  }

  getStatusThemeColorAlt(status: string | undefined): any {
    // if (active) {
    //   return blue[500]
    // }
    switch (status) {
      case "waiting":
        return {
          color: grey[800],
          backgroundColor: grey[100],
          borderColor: grey[500],
        }
      case "paused":
      case "started":
        return {
          color: green[800],
          backgroundColor: green[50],
          borderColor: green[500],
        }
      case "abandoned":
        return {
          color: red[800],
          backgroundColor: red[50],
          borderColor: red[500],
        }
      case "completed":
        return {
          color: grey[100],
          backgroundColor: grey[700],
          borderColor: grey[500],
        }
      default:
        return {
          color: grey[600],
          backgroundColor: grey[100],
          borderColor: grey[800],
        }
    }
  }

  getStatusIcon(status: string | undefined, active: boolean | undefined) {
    if (active) {
      return "WifiTethering"
    }
    switch (status) {
      case "waiting":
        return "AccessTime"
      case "started":
        return "PlayCircle"
      case "paused":
        return "PauseCircle"
      case "completed":
        return "Archive"
      default:
        return "ListAltOutlined"
    }
  }
}

export const surveyService = new SurveyService()
