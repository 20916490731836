import React, { useState } from "react"

import type { IFeature, IFormAttachment, ISurveyAttachment } from "../../../interfaces"
import FormRenderer from "../../FormBuilder/FormRenderer"
import { featureService } from "../../../services"
import { Box, CircularProgress, Stack, Typography, useTheme } from "@mui/material"

const FeatureFormComponent = ({
  feature,
  onSave,
  readOnly,
}: {
  readOnly: boolean
  onSave: (formData: string) => void
  feature: IFeature
}) => {
  const [attachments] = useState<ISurveyAttachment[]>([])
  const theme = useTheme()

  const handleOnFileUpload = async (payload: IFormAttachment): Promise<IFormAttachment | undefined> => {
    try {
      return await featureService.uploadImage(payload, String(feature.featureId))
    } catch (error) {
      console.log(error)
    }
    return undefined
  }

  const handleOnFileDelete = async (image: IFormAttachment): Promise<string> => {
    try {
      if (image.url) {
        return await featureService.deleteImage(image.url)
      }
    } catch (error) {
      console.log(error)
      return error as string
    }
    return "Error deleting the file. Please try again."
  }

  const renderLoadingContainer = () => (
    <Box width={"100%"} display={"flex"} justifyContent={"center"} py={12} alignItems={"center"}>
      <Stack alignItems={"center"} gap={2}>
        <CircularProgress />
        <Typography color={theme.palette.primary.main}>Please Wait...</Typography>
      </Stack>
    </Box>
  )

  return (
    <>
      {feature?.formData && feature?.formData.length > 0 ? (
        <FormRenderer
          readOnly={readOnly}
          attachments={attachments}
          onFileDelete={handleOnFileDelete}
          onFileUpload={handleOnFileUpload}
          onSave={onSave}
          formData={feature.formData}
        />
      ) : (
        renderLoadingContainer()
      )}
    </>
  )
}

export default FeatureFormComponent
