import type { ReactNode } from "react"
import React from "react"
import type { FormDataObject } from "../../../interfaces"
import { Button, Stack } from "@mui/material"
import { HiOutlinePlusCircle, HiOutlineMinusCircle } from "react-icons/hi2"

interface IProps {
  item: FormDataObject
  children: ReactNode
  type: "input" | "section"
  onRepeat: (item: FormDataObject) => void
  onDelete: (item: FormDataObject) => void
}

function RepeatableItem({ onRepeat, onDelete, ...props }: IProps) {
  if (props.item.repeatable === true) {
    return (
      <Stack
        direction={props.type === "input" ? "row" : "column-reverse"}
        gap={props.type === "input" ? 1 : 0}
        alignItems={"stretch"}>
        <Stack direction={"row"}>
          <Button
            onClick={() => onRepeat(props.item)}
            startIcon={props.type === "section" ? <HiOutlinePlusCircle strokeWidth={2} size={22} /> : undefined}
            sx={{
              textTransform: "capitalize",
              minWidth: 0,
              width: props.type === "section" ? "initial" : "40px",
            }}>
            {props.type === "section" ? <>Add</> : <HiOutlinePlusCircle strokeWidth={2} size={22} />}
          </Button>

          {props.item.duplicated === true && (
            <Button
              onClick={() => onDelete(props.item)}
              color="error"
              startIcon={props.type === "section" ? <HiOutlineMinusCircle strokeWidth={2} size={22} /> : undefined}
              sx={{
                textTransform: "capitalize",
                minWidth: 0,
                width: props.type === "section" ? "initial" : "40px",
              }}>
              {props.type === "section" ? <>Remove</> : <HiOutlineMinusCircle strokeWidth={2} size={22} />}
            </Button>
          )}
        </Stack>
        {props.children}
      </Stack>
    )
  }

  return props.children
}

export default RepeatableItem
