import { useMemo } from "react"

interface DeviceInfo {
  isFirefox: boolean
  isAndroid: boolean
  isViewport: boolean
  isTargetDevice: boolean
  deviceModel?: string
  manufacturer?: string
  browserVersion?: string
  isSpecialCase: boolean // True when both Firefox and target device conditions are met
  viewport: { height: number; width: number }
}

interface DeviceSettings {
  // Add your specific settings that should be different for Firefox on target device
  useAlternativeGeolocation: boolean
  enableLegacyFeatures: boolean
  customCacheStrategy: "aggressive" | "conservative"
  // Add more settings as needed
}

export const useDeviceDetection = (
  targetManufacturer: string,
  targetModel: string,
  targetViewport: { height: number; width: number },
): { deviceInfo: DeviceInfo; settings: DeviceSettings } => {
  const deviceInfo = useMemo((): DeviceInfo => {
    const ua = navigator.userAgent.toLowerCase()
    const isFirefox = ua.includes("firefox")
    const isAndroid = ua.includes("android")
    const browserVersion = ua.match(/firefox\/([0-9.]+)/)?.[1]

    // Extract manufacturer and model from UA string
    const androidInfo = ua.match(/android.*?;\s*(.*?)\s*(build|[;)"])/i)
    const deviceModel = androidInfo?.[1]?.toLowerCase() ?? ""

    const viewport = {
      width: window.innerWidth,
      height: window.innerHeight,
    }

    const isTargetDevice =
      deviceModel.includes(targetManufacturer.toLowerCase()) &&
      deviceModel.includes(targetModel.toLowerCase()) &&
      viewport.height === targetViewport.height &&
      viewport.width === targetViewport.width

    const isViewport = viewport.height === targetViewport.height && viewport.width === targetViewport.width

    return {
      isFirefox,
      isAndroid,
      isViewport,
      isTargetDevice,
      deviceModel: deviceModel || undefined,
      manufacturer: androidInfo?.[1]?.split(" ")[0] || undefined,
      browserVersion,
      isSpecialCase: isFirefox && isTargetDevice,
      viewport,
    }
  }, [targetManufacturer, targetModel, targetViewport])

  const settings = useMemo((): DeviceSettings => {
    if (deviceInfo.isSpecialCase) {
      // Return special case settings for Firefox on target device
      return {
        useAlternativeGeolocation: true,
        enableLegacyFeatures: true,
        customCacheStrategy: "conservative",
      }
    }

    // Return default settings
    return {
      useAlternativeGeolocation: false,
      enableLegacyFeatures: false,
      customCacheStrategy: "aggressive",
    }
  }, [deviceInfo.isSpecialCase])

  return { deviceInfo, settings }
}

// Example usage of the settings in different parts of your app
export const useSpecialDeviceSettings = () => {
  const settingsContext = {
    FIREFOX_SPECIFIC_FLAGS: {
      ALTERNATIVE_GEOLOCATION: "useAlternativeGeolocation",
      LEGACY_FEATURES: "enableLegacyFeatures",
      CACHE_STRATEGY: "customCacheStrategy",
    },
  }

  return settingsContext
}
