import { createTable, schemaMigrations } from "@nozbe/watermelondb/Schema/migrations"
import { TableName } from "./schema"

export default schemaMigrations({
  migrations: [
    {
      toVersion: 2, // Increment this number based on your current schema version
      steps: [
        //   // See "Migrations API" for more details
        //   createTable({
        //     name: TableName.Uploads2,
        //     columns: [
        //       { name: "blob_name", type: "string" },
        //       { name: "file_name", type: "string" },
        //       { name: "file_size", type: "number" },
        //       { name: "uploaded_blocks", type: "string" }, // JSON string
        //       { name: "created_at", type: "number" },
        //       { name: "updated_at", type: "number" },
        //     ],
        //   }),
        // See "Migrations API" for more details
        //   createTable({
        //     name: TableName.Upload,
        //     columns: [
        //       { name: "blob_name", type: "string" },
        //       { name: "file_name", type: "string" },
        //       { name: "file_size", type: "number" },
        //       { name: "uploaded_blocks", type: "string" }, // JSON string
        //       { name: "created_at", type: "number" },
        //       { name: "updated_at", type: "number" },
        //     ],
        //   }),
      ],
    },
  ],
})
