import { default as MuiIconButton, type IconButtonProps } from "@mui/material/IconButton"

interface IProps extends IconButtonProps {
  id: string //? Makes the id required for IconButtons
}

const IconButton = (props: IProps) => {
  return <MuiIconButton {...props} />
}

export default IconButton
