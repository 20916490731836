import type { IWorld } from "../interfaces/world.interface"
import { BASE_API_URL } from "../constants/commonStrings.constant"
import { apiService } from "./api.service"
import type { IProject } from "../interfaces/project.interface"

class WorldService {
  async getWorlds(): Promise<IWorld[]> {
    const url = BASE_API_URL + "/world"
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async getWorld(id: string): Promise<[IWorld, IProject[]]> {
    const url = BASE_API_URL + "/world/" + id
    const projectUrl = BASE_API_URL + "/project"
    let projects: IProject[] = []
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })

    const { data }: { data: IWorld } = response

    // Fetch related projects
    if (data && data.worldId) {
      const res: { data: IProject[] } = await apiService.get(projectUrl, { withCredentials: false })
      projects = res.data && res.data.filter((p) => p.worldId === data.worldId)
    }

    return [data, projects]
  }

  async addWorld(payload: IWorld): Promise<IWorld> {
    const url = BASE_API_URL + "/world"

    const response = await apiService.post(url, payload, { withCredentials: false })

    const { data } = response
    return data
  }

  async updateWorld(payload: IWorld): Promise<IWorld> {
    const url = BASE_API_URL + "/world"

    const response = await apiService.put(url, payload, { withCredentials: false })

    const { data } = response
    return data
  }
}

export const worldService = new WorldService()
