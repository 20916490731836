import { useDatabase } from "@nozbe/watermelondb/hooks"
import { Q } from "@nozbe/watermelondb"
import type WorkType from "../../model/WorkType"
import { TableName } from "../../model/schema"
import useObservable from "../useObservable"

export function useWorkTypes() {
  const database = useDatabase()

  const workTypesQuery = database.collections.get<WorkType>(TableName.WorkType).query(Q.sortBy("WorkTypeName", Q.asc))

  const $workTypes = useObservable<WorkType[]>(workTypesQuery.observe(), [], [database])

  return { $workTypes }
}
