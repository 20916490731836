import { BASE_API_URL, WORKSPACE } from "../constants"
import { apiService } from "./api.service"
import type { IUser, ITarget, IRole, IPermission } from "@utilisourcepackagelibdev/utilisourcepackagelib"

class SecurityService {
  async getUser(email: string): Promise<IUser | undefined> {
    const url = BASE_API_URL + "/user/" + email
    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        workspace: WORKSPACE,
      },
    })
    const { data }: { data: IUser } = response
    return data
  }

  async getPermissions(): Promise<IPermission[]> {
    const url = BASE_API_URL + "/security/permissions"
    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        workspace: WORKSPACE,
      },
    })
    const { data }: { data: IPermission[] } = response
    return data
  }

  async getRole(roleId: number): Promise<IRole | undefined> {
    const url = BASE_API_URL + "/security/role/" + roleId
    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        workspace: WORKSPACE,
      },
    })
    const { data }: { data: IRole } = response
    return data
  }

  async getTargets(): Promise<ITarget[]> {
    const url = BASE_API_URL + "/security/target"
    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        workspace: "MapSource",
      },
    })
    const { data }: { data: ITarget[] } = response
    return data
  }

  async addTarget(payload: ITarget): Promise<ITarget | undefined> {
    const url = BASE_API_URL + "/security/target"
    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })

    const { data }: { data: ITarget } = response
    return data
  }

  async updateTarget(payload: ITarget): Promise<ITarget | undefined> {
    const url = BASE_API_URL + "/security/target"
    const response = await apiService.put(url, payload, {
      withCredentials: false,
    })

    const { data }: { data: ITarget } = response
    return data
  }

  async deleteTarget(payload: ITarget): Promise<ITarget | undefined> {
    const url = BASE_API_URL + "/security/target"
    const response = await apiService.delete(url, {
      withCredentials: false,
      data: payload,
    })

    const { data }: { data: ITarget } = response
    return data
  }

  async getRoles(deep: boolean = false): Promise<IRole[]> {
    const url = BASE_API_URL + "/security/role"
    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        workspace: WORKSPACE,
        deep,
      },
    })
    const { data }: { data: IRole[] } = response
    return data
  }

  async addRole(payload: IRole): Promise<IRole | undefined> {
    const url = BASE_API_URL + "/security/role"
    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })
    const { data }: { data: IRole } = response
    return data
  }

  async updateRole(payload: IRole): Promise<IRole | undefined> {
    const url = BASE_API_URL + "/security/role"
    const response = await apiService.put(url, payload, {
      withCredentials: false,
    })

    const { data }: { data: IRole } = response
    return data
  }

  async deleteRole(payload: IRole): Promise<IRole | undefined> {
    const url = BASE_API_URL + "/security/role/" + payload.roleId
    const response = await apiService.delete(url, {
      withCredentials: false,
      params: {
        workspace: "MapSource",
      },
    })

    const { data }: { data: IRole } = response
    return data
  }

  async addPermission(permission: IPermission) {
    const url = BASE_API_URL + "/security/permissions"
    const response = await apiService.post(url, permission, {
      withCredentials: false,
    })
    const { data }: { data: IPermission } = response
    return data
  }

  async updatePermission(permission: IPermission) {
    const url = BASE_API_URL + "/security/permissions"
    const response = await apiService.put(url, permission, {
      withCredentials: false,
    })
    const { data }: { data: IPermission } = response
    return data
  }

  async deletePermission(permission: IPermission, destructive: boolean = false) {
    const url = BASE_API_URL + "/security/permissions/" + permission.permissionId
    const response = await apiService.delete(url, {
      withCredentials: false,
      params: {
        destructive,
      },
    })
    const { data }: { data: string } = response
    return data
  }
}

export const securityService = new SecurityService()
