import { Badge } from "@mui/material"
import { BsDatabaseUp } from "react-icons/bs"
import React, { useContext, useEffect, useState } from "react"
import SettingsContext from "../../../contexts/settings.context"

const BlobCountBadge = () => {
  const { settingsState } = useContext(SettingsContext)
  const [blobCount, setBlobCount] = useState(0)

  useEffect(() => {
    if (settingsState.idbUsage) {
      setBlobCount(settingsState.idbUsage.blobCount)
    }
  }, [settingsState.idbUsage])

  if (blobCount === 0) {
    return null
  }

  return (
    <Badge badgeContent={blobCount} color="error" overlap="circular" max={9999}>
      <BsDatabaseUp size={24} />
    </Badge>
  )
}

export default React.memo(BlobCountBadge)
