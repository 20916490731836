import { BASE_API_URL } from "../constants/commonStrings.constant"
import type {
  ICategory,
  IFormAttachment,
  IFormTemplate,
  IWorkType,
  IFeatureType,
  FieldDataOptions,
} from "../interfaces"
import type { IFormStyled } from "../interfaces/formResponse.interface"
import { apiService } from "./api.service"

class FormService {
  async getForms(): Promise<IFormTemplate[]> {
    const url = BASE_API_URL + "/form"
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data } = response
    console.log(data)
    return data
  }

  async search(searchText: string, workTypeId: number, category: string, projectId: number): Promise<IFormTemplate[]> {
    const url = BASE_API_URL + "/form/search"
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
      params: {
        searchText,
        workTypeId,
        category: category !== "All" ? category : null,
        projectId,
      },
    })
    const { data }: { data: IFormTemplate[] } = response
    return data.sort((a, b) => a.formTitle.localeCompare(b.formTitle))
  }

  async getFormsStyled(projectId: number): Promise<IFormStyled[]> {
    const url = BASE_API_URL + "/form/get-styled"
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
      params: {
        projectId,
      },
    })
    const { data }: { data: IFormTemplate[] } = response
    return data.sort((a, b) => a.formTitle.localeCompare(b.formTitle))
  }

  async getForm(id: string): Promise<IFormTemplate> {
    const url = BASE_API_URL + "/form/" + id
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async getWorkTypes(): Promise<IWorkType[]> {
    const url = BASE_API_URL + "/form/work-types"
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async getOptions(path: string, projectId: number, surveyId?: number, formId?: number): Promise<FieldDataOptions> {
    const url = BASE_API_URL + path
    const response = await apiService.get(url, {
      withCredentials: false,
      params: {
        projectId,
        surveyId,
        formId,
      },
    })
    //console.log(response)
    const { data } = response
    return data
  }

  async getWorkType(workTypeId: number): Promise<IWorkType> {
    const url = BASE_API_URL + "/form/work-type/" + workTypeId
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async getFeatureTypes(): Promise<IFeatureType[]> {
    const url = BASE_API_URL + "/feature/feature-types"
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async getFeatureTypesByWorkType(workTypeId: number): Promise<IFeatureType[]> {
    const url = BASE_API_URL + "/feature/feature-types-by-work-type/" + workTypeId
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async getCategories(): Promise<ICategory[]> {
    const url = BASE_API_URL + "/form/categories"
    const response = await apiService({
      url,
      method: "GET",
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async addForm(payload: IFormTemplate): Promise<IFormTemplate> {
    const url = BASE_API_URL + "/form"
    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })
    const { data } = response
    return data
  }

  async updateForm(payload: IFormTemplate): Promise<IFormTemplate> {
    const url = BASE_API_URL + "/form"
    const response = await apiService.put(url, payload, {
      withCredentials: false,
    })

    const { data } = response
    return data
  }

  async deleteForm(id: number): Promise<string> {
    const url = BASE_API_URL + `/form/${id}`
    const response = await apiService.delete(url, {
      withCredentials: false,
    })
    const { statusText } = response
    return statusText
  }

  async uploadAttachments(payload: IFormAttachment[]): Promise<string> {
    const url = BASE_API_URL + `/form/upload-attachments`
    const response = await apiService.post(url, payload, {
      withCredentials: false,
    })
    const { data } = response
    return data
  }
}

export const formService = new FormService()
