import { BASE_API_URL } from "../constants/commonStrings.constant"
import { apiService } from "./api.service"

class StorageService {
  async getAzureSAS(blobName: string, isWrite: boolean): Promise<string> {
    const url = BASE_API_URL + "/storage/sas-token"
    const response = await apiService.post(url, { blobName, isWrite }, { withCredentials: false })
    const { data } = response
    return data
  }
}

export const storageService = new StorageService()
