import { useTheme, InputAdornment, TextField, Autocomplete } from "@mui/material"
import React, { useRef, useEffect } from "react"
import type { FormDataTypeProps } from "../../../interfaces"
import DynamicIcon from "../lib/DynamicIcon"
import { BASE_ESRI_API_KEY } from "../../../constants"
import { geolocationService } from "../../../services"

interface AddressComponents {
  address: string
  streetAddress?: string
  city?: string
  region?: string
  postal?: string
  countryCode?: string
  [key: string]: any // Include any additional attributes
}

const AddressSearchInput: React.FC<FormDataTypeProps> = ({ onDataChange, value: propValue, ...props }) => {
  const theme = useTheme()

  if (propValue === undefined) {
    propValue = { inputValue: "" }
  }
  const [inputValue, setInputValue] = React.useState(propValue && propValue.inputValue ? propValue.inputValue : "")
  const [options, setOptions] = React.useState<any[]>([])
  const [selectedCandidate, setSelectedCandidate] = React.useState<AddressComponents | null>(null)
  const [location, setLocation] = React.useState<any[]>([])

  const ref = useRef<HTMLDivElement>(null)

  // Update inputValue when propValue changes
  useEffect(() => {
    if (propValue.inputValue !== undefined && propValue.inputValue !== inputValue) {
      setInputValue(propValue.inputValue)
    }
  }, [propValue.inputValue])

  useEffect(() => {
    geolocationService.getCurrentLocation((position) => {
      if (!position) return
      setLocation([position.coords.latitude, position.coords.longitude])
    })
  }, [])

  useEffect(() => {
    let active = true

    if (inputValue.length < 3) {
      setOptions([])
      return undefined
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=${encodeURIComponent(
            inputValue,
          )}&f=json&token=${BASE_ESRI_API_KEY}${location.length > 0 ? `&location=${location[1]},${location[0]}` : ""}`,
        )
        const data = await response.json()

        if (active) {
          if (data.suggestions) {
            setOptions(data.suggestions)
          } else {
            setOptions([])
          }
        }
      } catch (error) {
        console.error(error)
        setOptions([])
      }
    }

    fetchData()

    return () => {
      active = false
    }
  }, [inputValue])

  const handleChange = async (event: any, newValue: any) => {
    if (typeof newValue === "string") {
      // User typed a value and pressed Enter or blurred the input
      setInputValue(newValue)
      setSelectedCandidate(null)
      onDataChange(props.id, { inputValue: newValue })
    } else if (newValue && newValue.magicKey) {
      // User selected an option from the list
      try {
        const response = await fetch(
          `https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?magicKey=${encodeURIComponent(
            newValue.magicKey,
          )}&f=json&outFields=*&token=${BASE_ESRI_API_KEY}`,
        )
        const data = await response.json()
        console.log("findAddressCandidates", data)
        if (data.candidates && data.candidates.length > 0) {
          const candidate = data.candidates[0]
          const attributes = candidate.attributes
          const addressComponents: AddressComponents = {
            address: candidate.address,
            streetAddress: attributes.Address,
            city: attributes.City,
            region: attributes.Region,
            postal: attributes.Postal,
            countryCode: attributes.CountryCode,
            ...attributes, // Include any additional attributes if needed
          }
          setSelectedCandidate(addressComponents)
          setInputValue(candidate.address)
          // Pass both the input value and the address components to the parent
          onDataChange(props.id, {
            inputValue: candidate.address,
            addressComponents: addressComponents,
          })
        } else {
          setSelectedCandidate(null)
          setInputValue(newValue.text)
          onDataChange(props.id, { inputValue: newValue.text })
        }
      } catch (error) {
        console.error(error)
        setSelectedCandidate(null)
        setInputValue(newValue.text || "")
        onDataChange(props.id, { inputValue: newValue.text || "" })
      }
    } else {
      // newValue is null or undefined
      setSelectedCandidate(null)
      setInputValue("")
      onDataChange(props.id, { inputValue: "" })
    }
  }

  return (
    <Autocomplete
      ref={ref}
      freeSolo
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue)

        if (reason === "clear") {
          setSelectedCandidate(null)
          onDataChange(props.id, { inputValue: "" })
        } else if (reason === "input") {
          setSelectedCandidate(null) // Clear previous selection
          onDataChange(props.id, { inputValue: newInputValue })
        }
      }}
      options={options}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.text || "")}
      renderOption={(propsOption, option) => (
        <li {...propsOption} key={option.magicKey || option.text}>
          {option.text}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={props.disabled}
          sx={{
            "& > .MuiInputBase-root": {
              backgroundColor: theme.palette.background.paper,
            },
          }}
          variant="outlined"
          fullWidth
          label={props.label}
          name={props.name}
          size="small"
          placeholder={props.placeholder}
          required={props.required}
          helperText={props.hintText}
          InputProps={{
            ...params.InputProps,
            readOnly: props.readOnly,
            required: props.required,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                <InputAdornment position="end">
                  <DynamicIcon name={props.type} />
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default AddressSearchInput
