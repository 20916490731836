import { InputAdornment, TextField, useTheme } from "@mui/material"
import { useState, type FC } from "react"
import type { FormDataTypeProps } from "../../../interfaces/formBuilder.interface"
import DynamicIcon from "../lib/DynamicIcon"

const EmailFieldInput: FC<FormDataTypeProps> = ({ onDataChange, ...props }: FormDataTypeProps) => {
  const theme = useTheme()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("TextFieldInput -> e.target.value", e.target.value)
    setValue(e.target.value)
    onDataChange(props.id, e.target.value)
  }

  const [value, setValue] = useState(props.value ?? null)
  return (
    <TextField
      value={value ?? ""}
      disabled={props.disabled}
      sx={{ bgcolor: theme.palette.background.paper }}
      variant="outlined"
      fullWidth
      label={props.label}
      name={props.name}
      type="email"
      autoComplete="off"
      size="small"
      placeholder={props.placeholder}
      onChange={onChange}
      helperText={props.hintText}
      InputProps={{
        readOnly: props.readOnly,
        endAdornment: (
          <InputAdornment position="end">
            <DynamicIcon name={props.type} />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default EmailFieldInput
