import type { ButtonProps } from "@mui/material/Button"
import { default as MuiButton } from "@mui/material/Button"

interface IProps extends ButtonProps {
  id: string //? Makes the id required for buttons
}

const Button = (props: IProps) => {
  return <MuiButton size="small" sx={{ fontWeight: "bold" }} {...props} />
}

export default Button
