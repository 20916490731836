import type { ILayout } from "../layout.context"

export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR"
export const OPEN_SIDEBAR = "OPEN_SIDEBAR"
export const CLOSE_ACTION_SHEET = "CLOSE_ACTION_SHEET"
export const OPEN_ACTION_SHEET = "OPEN_ACTION_SHEET"
export const TOGGLE_NAVIGATION_MODE = "TOGGLE_NAVIGATION_MODE"

export const defaultSettings: ILayout = {
  theme: "light",
  sidebarOpen: false,
  sidebarMode: "half",
  navigationMode: "full",
  sheetOpen: false,
}

export const initialLayoutState: ILayout = defaultSettings

interface IAction {
  type: string
  payload: ILayout
}

export const layoutReducer = (state: ILayout = initialLayoutState, action: IAction) => {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return {
        ...state,
        sidebarOpen: true,
        sidebar: action.payload.sidebar,
        sidebarMode: action.payload.sidebarMode,
      }
    case CLOSE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: false,
        sidebar: null,
      }
    case TOGGLE_NAVIGATION_MODE:
      return {
        ...state,
        navigationMode: state.navigationMode === "full" ? "minimized" : "full",
      }
    case OPEN_ACTION_SHEET:
      return {
        ...state,
        sheetOpen: true,
        actionSheet: action.payload.actionSheet,
      }
    case CLOSE_ACTION_SHEET:
      return {
        ...state,
        sheetOpen: false,
        actionSheet: null,
      }
    default:
      return state
  }
}
