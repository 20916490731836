import React, { type FC } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack, Typography, useTheme } from "@mui/material"
import type { FormDataObject, FormDataProps } from "../../../interfaces/formBuilder.interface"
import DynamicComponent from "../lib/DynamicComponent"
import { ExpandMore } from "@mui/icons-material"
import ConditionalItem from "../lib/ConditionalItem"
import RepeatableItem from "../lib/RepeatableItem"

export interface SectionProps extends FormDataObject {
  value?: any
  onRepeat: (item: FormDataObject) => void
  onDelete: (item: FormDataObject) => void
}

const Section: FC<SectionProps & FormDataProps> = ({
  collapsible,
  children,
  control,
  onDataChange,
  onFileDelete,
  onRepeat,
  onDelete,
  onFileUpload,
  ...props
}: FormDataObject & FormDataProps & SectionProps) => {
  const theme = useTheme()

  const renderChildren = () => {
    return (
      <Stack gap={2}>
        {children &&
          children.map((item) => {
            return (
              <RepeatableItem onRepeat={onRepeat} onDelete={onDelete} type="input" item={item} key={item.id}>
                <ConditionalItem item={item} items={children}>
                  <DynamicComponent
                    {...item}
                    onFileUpload={onFileUpload}
                    onFileDelete={onFileDelete}
                    readOnly={props.readOnly}
                    control={control}
                    onDataChange={(inputId, value) => {
                      onDataChange(inputId, value, props.id)
                    }}
                  />
                </ConditionalItem>
              </RepeatableItem>
            )
          })}
      </Stack>
    )
  }

  const renderCollapsible = () => (
    <Accordion
      defaultExpanded
      elevation={0}
      disableGutters
      sx={{ backgroundColor: "transparent", border: `solid 2px ${theme.palette.grey[200]}` }}>
      <AccordionSummary sx={{ backgroundColor: theme.palette.grey[100] }} expandIcon={<ExpandMore />}>
        <Typography>{props.label}</Typography>
      </AccordionSummary>
      <AccordionDetails>{renderChildren()}</AccordionDetails>
    </Accordion>
  )

  if (collapsible) {
    return renderCollapsible()
  }

  return (
    <Box p={2} sx={{ width: "100%" }}>
      <Divider sx={{ marginBottom: 2 }}>
        <Typography typography={"subtitle"} color={theme.palette.grey[600]}>
          {props.label}
        </Typography>
      </Divider>
      {renderChildren()}
    </Box>
  )
}

export default Section
