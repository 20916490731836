import type { DialogProps } from "@mui/material/Dialog"
import { default as MDialog } from "@mui/material/Dialog"

interface IProps extends DialogProps {}

const MuiDialog = (props: IProps) => {
  return <MDialog {...props} />
}

export default MuiDialog
