import type { Query } from "@nozbe/watermelondb"
import { Model } from "@nozbe/watermelondb"
import { field, readonly, date, children } from "@nozbe/watermelondb/decorators"
import { TableName } from "./schema"
import type Form from "./Form"
import type { Associations } from "@nozbe/watermelondb/Model"

export default class WorkType extends Model {
  static table = TableName.WorkType
  static associations: Associations = {
    [TableName.Form]: { type: "has_many", foreignKey: "workTypeKey" },
  }

  @field("workTypeId") workTypeId!: number
  @field("workTypeName") workTypeName?: string
  @field("workTypeDescription") workTypeDescription?: string
  @field("sort_order") sortOrder?: number
  @readonly @date("created_at") createdAt!: number
  @field("createdBy") createdBy?: string
  @field("createdOffset") createdOffset?: number
  @readonly @date("updated_at") updatedAt?: number
  @field("updatedBy") updatedBy?: string
  @field("updatedOffset") updatedOffset?: number
  @readonly @date("deletedAt") deletedAt?: Date
  @field("deletedBy") deletedBy?: string
  @field("deletedOffset") deletedOffset?: number
  @field("icon") icon?: string
  @field("locationRequired") locationRequired?: boolean
  @field("locationDisabled") locationDisabled?: boolean
  @field("locationHidden") locationHidden?: boolean

  @children("forms") forms?: Query<Form>
}
