import { AuthProvider } from "./auth.context"
import { authReducer, initialAuthState } from "./reducers/auth.reducer"
import { SettingsProvider } from "./settings.context"
import { settingsReducer, initialSettingsState } from "./reducers/settings.reducer"
import { LayoutProvider } from "./layout.context"
import { layoutReducer, initialLayoutState } from "./reducers/layout.reducer"
import IDBSimulator from "./IDBSimulator"

const GlobalStateProvider = (props: any) => (
  <SettingsProvider reducer={settingsReducer} initialState={initialSettingsState}>
    <AuthProvider reducer={authReducer} initialState={initialAuthState}>
      <LayoutProvider reducer={layoutReducer} initialState={initialLayoutState}>
        {props.children}
      </LayoutProvider>
    </AuthProvider>
    {/* {process.env.NODE_ENV === "development" && <IDBSimulator />} */}
  </SettingsProvider>
)

export default GlobalStateProvider
