import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import type { FormDataTypeProps } from "../../../interfaces/formBuilder.interface"
import SettingsContext from "../../../contexts/settings.context"
import { formService } from "../../../services/form.service"

const MultiDropdown: React.FC<FormDataTypeProps & { onSelection?: (value: string[]) => void }> = ({
  onSelection,
  onDataChange,
  options,
  dataUrl,
  ...props
}: FormDataTypeProps & { onSelection?: (value: string[]) => void }) => {
  const theme = useTheme()

  const [_options, setOptions] = useState(options)
  const [loading, setLoading] = useState(false)
  const { settingsState } = useContext(SettingsContext)
  const [value, setValue] = useState(props.value ?? [])

  const loadOptions = useCallback(async () => {
    if (dataUrl) {
      console.log("fetching options...")
      setLoading(true)
      try {
        if (settingsState && settingsState.activeProject && settingsState.activeProject.projectId) {
          const response = await formService.getOptions(dataUrl, settingsState.activeProject.projectId)
          if (response) setOptions(response)
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [dataUrl, settingsState])

  useEffect(() => {
    if (dataUrl) {
      loadOptions()
    }
  }, [dataUrl, loadOptions])

  const getOptionLabel = (value: string) => {
    if (!_options) return value
    const option = Object.values(_options).find((opt) =>
      opt.pkey ? opt.pkey.toString() === value.toString() : opt.value.toString() === value.toString(),
    )
    return option ? option.value : value
  }

  useEffect(() => (options !== undefined ? setValue(props.value) : undefined), [])
  useEffect(() => {
    const handleReload = (event: CustomEvent) => {
      if (event.detail.inputId === props.id) {
        console.log("rules reloadData triggered in " + props.label)
        loadOptions()
      }
    }
    window.addEventListener("reloadData", handleReload as EventListener)

    return () => {
      window.removeEventListener("reloadData", handleReload as EventListener)
    }
  }, [props.id])

  const onChange = (value: string[]) => {
    setValue(value)
    onDataChange(props.id, value)
  }

  return (
    <FormControl size="small" required={props.required} variant="outlined" fullWidth>
      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={props.id}
        readOnly={_options === undefined}
        label={props.label}
        name={props.name}
        multiple={true}
        startAdornment={
          loading && (
            <Box width={12} height={12}>
              <CircularProgress size={18} />
            </Box>
          )
        }
        native={false}
        size="small"
        value={(value as string[]) ?? []}
        onChange={(e) => {
          const _value = e.target.value as string[]
          onChange(e.target.value as string[])
          if (onSelection) onSelection(_value)
        }}
        sx={{
          bgcolor: theme.palette.background.paper,
        }}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected &&
              (selected as string[]).map((value, index) => (
                <Chip size="small" key={index} label={getOptionLabel(value)} />
              ))}
          </Box>
        )}>
        {_options && _options !== undefined && Object.keys(_options).length > 0 ? (
          Object.keys(_options).map((key, index) => (
            <MenuItem key={index} value={_options[key].pkey ? _options[key].pkey : _options[key].value}>
              <Checkbox
                value={_options[key].pkey ? _options[key].pkey : _options[key].value}
                size="small"
                checked={
                  value &&
                  (value as Array<string>)
                    .map((v) => v.toString())
                    .includes(_options[key].pkey ? _options[key].pkey.toString() : _options[key].value.toString())
                }
              />
              <ListItemText primaryTypographyProps={{ fontSize: 12 }} primary={_options[key].value} />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
      {props.hintText && <FormHelperText>{props.hintText}</FormHelperText>}
    </FormControl>
  )
}

export default MultiDropdown
