import type { ReactNode, Reducer } from "react"
import React, { useReducer } from "react"
import {
  CLOSE_ACTION_SHEET,
  CLOSE_SIDEBAR,
  OPEN_ACTION_SHEET,
  OPEN_SIDEBAR,
  TOGGLE_NAVIGATION_MODE,
} from "./reducers/layout.reducer"

export interface ILayoutContext {
  layoutState: ILayout
  openSidebar: (payload: ReactNode, mode?: "half" | "full") => void
  closeSidebar: () => void
  closeActionSheet: () => void
  openActionSheet: (payload: ReactNode) => void
  toggleNavigation: () => void
}

export interface ILayout {
  theme: "dark" | "light"
  sidebarOpen: boolean
  sheetOpen: boolean
  actionSheet?: ReactNode
  sidebar?: ReactNode
  sidebarMode: "half" | "full"
  navigationMode: "minimized" | "full"
}

const LayoutContext = React.createContext<ILayoutContext>({
  layoutState: {
    theme: "light",
    sidebarOpen: false,
    sheetOpen: false,
    navigationMode: "full",
    sidebarMode: "half",
  },
  closeActionSheet: () => {},
  openActionSheet: () => {},
  openSidebar: () => {},
  closeSidebar: () => {},
  toggleNavigation: () => {},
})

export const LayoutProvider = ({ children, reducer, initialState }: any) => {
  const [globalLayoutState, layoutDispatch] = useReducer<Reducer<ILayout, any>>(reducer, initialState)

  const closeSidebar = () => {
    layoutDispatch({
      type: CLOSE_SIDEBAR,
      payload: {},
    })
  }

  const toggleNavigation = () => {
    layoutDispatch({
      type: TOGGLE_NAVIGATION_MODE,
      payload: {},
    })
  }

  const openSidebar = (payload: ReactNode, mode?: "half" | "full") => {
    if (!mode) {
      mode = "full"
    }
    layoutDispatch({
      type: OPEN_SIDEBAR,
      payload: {
        sidebar: payload,
        sidebarMode: mode,
      },
    })
  }

  const closeActionSheet = () => {
    layoutDispatch({
      type: CLOSE_ACTION_SHEET,
      payload: {},
    })
  }

  const openActionSheet = (payload: ReactNode) => {
    layoutDispatch({
      type: OPEN_ACTION_SHEET,
      payload: {
        actionSheet: payload,
      },
    })
  }

  return (
    <LayoutContext.Provider
      value={{
        layoutState: globalLayoutState,
        closeSidebar,
        toggleNavigation,
        closeActionSheet,
        openActionSheet,
        openSidebar,
      }}>
      {children}
    </LayoutContext.Provider>
  )
}

export const LayoutStateConsumer = LayoutContext.Consumer

export default LayoutContext
