import type { FormDataObject, FormLibaryComponent } from "../../../interfaces/formBuilder.interface"
import {
  DateFieldInput,
  DecimalFieldInput,
  SingleDropdown,
  MultiDropdown,
  EmailFieldInput,
  NumberFieldInput,
  PhoneFieldInput,
  TextAreaFieldInput,
  TextFieldInput,
  AddressSearch,
  TimeFieldInput,
  SignaturePad,
  HeadingItem,
  ParagraphItem,
  Section,
  ImageItem,
  ImageUploaderInput,
} from "../inputs"
import { PiCamera, PiImageSquare, PiParagraph, PiSignature, PiTextT } from "react-icons/pi"
import { IoTimeOutline } from "react-icons/io5"
import { FaMapMarkedAlt } from "react-icons/fa"
import { v4 } from "uuid"
import type { DraggableLocation } from "@hello-pangea/dnd"
import { useEffect, useState } from "react"
import type { IconType } from "react-icons"
import { HiOutlineCalendar, HiOutlinePhone } from "react-icons/hi2"
import { LuHeading } from "react-icons/lu"
import { CgSelectR } from "react-icons/cg"

import { BsTextareaT } from "react-icons/bs"
import { VscChecklist, VscMail } from "react-icons/vsc"
import { TbDecimal } from "react-icons/tb"
import { AiOutlineFieldNumber } from "react-icons/ai"

const defaultInitializer = (index: number) => index

function createRange<T = number>(length: number, initializer: (index: number) => any = defaultInitializer): T[] {
  return [...new Array(length)].map((_, index) => initializer(index))
}

const reorder = (list: FormDataObject[], source: DraggableLocation, destination: DraggableLocation) => {
  const items = Array.from(list)

  const sectionIndex = items.findIndex((des) => des.id === source.droppableId.replace("section-", ""))
  if (sectionIndex >= 0) {
    const [removed] = items[sectionIndex].children.splice(source.index, 1)
    items[sectionIndex].children.splice(destination.index, 0, removed)
  }

  return items
}

const copy = (
  source: Array<FormDataObject>,
  destination: Array<FormDataObject>,
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
): { destClone: FormDataObject[]; newItem: FormDataObject } => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const item = sourceClone[droppableSource.index]
  const sectionIndex = destClone.findIndex(
    (des) => des.id === `${droppableDestination.droppableId.replace("section-", "")}`,
  )

  const newItem = {
    ...item,
    id: v4(),
  }

  if (sectionIndex >= 0) {
    //const section = destClone[sectionIndex];

    destClone[sectionIndex].children.splice(droppableDestination.index, 0, newItem)

    console.log(destClone[sectionIndex])
    // destClone.splice(sectionIndex, 0, section);
  }

  return { destClone, newItem }
}

const move = (
  list: Array<FormDataObject>,
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation,
) => {
  const items = Array.from(list)

  const sourceSectionIndex = items.findIndex((des) => des.id === droppableSource.droppableId.replace("section-", ""))
  const destinationSectionIndex = items.findIndex(
    (des) => des.id === droppableDestination.droppableId.replace("section-", ""),
  )

  if (sourceSectionIndex >= 0 && destinationSectionIndex >= 0) {
    const [removed] = items[sourceSectionIndex].children.splice(droppableSource.index, 1)
    items[destinationSectionIndex].children.splice(droppableDestination.index, 0, removed)
  }

  return items
}

const formLibraryComponents: FormLibaryComponent = {
  text: TextFieldInput,
  date: DateFieldInput,
  time: TimeFieldInput,
  textarea: TextAreaFieldInput,
  number: NumberFieldInput,
  decimal: DecimalFieldInput,
  email: EmailFieldInput,
  multiDropdown: MultiDropdown,
  singleDropdown: SingleDropdown,
  phone: PhoneFieldInput,
  signature: SignaturePad,
  heading: HeadingItem,
  paragraph: ParagraphItem,
  image: ImageItem,
  section: Section,
  camera: ImageUploaderInput,
  address: AddressSearch,
}

const elementItems: FormDataObject[] = [
  {
    id: v4(),
    label: "Heading",
    isEditing: true,
    required: false,
    name: "Heading",
    value: "Heading",
    type: "heading",
    children: [],
  },

  {
    id: v4(),
    label: "Paragraph",
    isEditing: true,
    required: false,
    name: "Paragraph",
    value: "Paragraph",
    type: "paragraph",
    children: [],
  },

  {
    id: v4(),
    required: false,
    isEditing: true,
    label: "Image",
    name: "Image",

    type: "image",
    children: [],
    options: {
      width: {
        name: "width",
        value: "100%",
      },
      alignment: {
        name: "alignment",
        value: "left",
      },
    },
  },
]

const libraryItems: FormDataObject[] = [
  {
    id: v4(),
    label: "Text Field",
    required: false,
    isEditing: true,
    name: "Text",
    type: "text",
    children: [],
    control: undefined,
  },
  {
    id: v4(),
    label: "Date Field",
    required: false,
    name: "Date",
    isEditing: true,
    type: "date",
    children: [],
    control: undefined,
  },

  {
    id: v4(),
    label: "Time Picker",
    required: false,
    isEditing: true,
    name: "Time",
    type: "time",
    children: [],
    control: undefined,
  },

  {
    id: v4(),
    required: false,
    label: "Multiline Field",
    name: "Multiline Text",
    isEditing: true,
    type: "textarea",
    children: [],
    control: undefined,
  },

  {
    id: v4(),
    label: "Number Field",
    required: false,
    name: "Number",
    type: "number",
    isEditing: true,
    children: [],
    control: undefined,
  },

  {
    id: v4(),
    label: "Decimal Field",
    required: false,
    name: "Decimal",
    type: "decimal",
    isEditing: true,
    children: [],
    control: undefined,
  },

  {
    id: v4(),
    required: false,
    label: "Email Field",
    name: "Email",
    isEditing: true,
    type: "email",
    children: [],
    control: undefined,
  },
  {
    id: v4(),
    required: false,
    label: "Address Field",
    name: "Address",
    isEditing: true,
    type: "address",
    children: [],
    control: undefined,
  },

  {
    id: v4(),
    label: "Single Dropdown",
    required: false,
    name: "Single Dropdown",
    type: "singleDropdown",
    isEditing: true,
    children: [],
    control: undefined,
  },

  {
    id: v4(),
    label: "Multi Dropdown",
    required: false,
    name: "Multi Dropdown",
    isEditing: true,
    type: "multiDropdown",
    children: [],
    control: undefined,
  },

  {
    id: v4(),
    required: false,
    label: "Phone",
    name: "Phone",
    type: "phone",
    children: [],
    isEditing: true,
    control: undefined,
  },

  {
    id: v4(),
    required: false,
    label: "Signature",
    isEditing: true,
    name: "Signature",
    type: "signature",
    children: [],
    control: undefined,
  },

  {
    id: v4(),
    required: false,
    label: "Photos",
    isEditing: true,
    name: "Photos",
    type: "camera",
    children: [],
    control: undefined,
  },
]

export enum Position {
  above = 1,
  current = 0,
  below = -1,
}

export const fieldTypeIcons: Record<string, IconType> = {
  text: PiTextT,
  date: HiOutlineCalendar,
  time: IoTimeOutline,
  textarea: BsTextareaT,
  decimal: TbDecimal,
  number: AiOutlineFieldNumber,
  email: VscMail,
  singleDropdown: CgSelectR,
  multiDropdown: VscChecklist,
  phone: HiOutlinePhone,
  signature: PiSignature,
  heading: LuHeading,
  paragraph: PiParagraph,
  image: PiImageSquare,
  camera: PiCamera,
  address: FaMapMarkedAlt,
}

const insertFormObjectAt = (
  index: number,
  item: FormDataObject,
  items: Array<FormDataObject>,
): Array<FormDataObject> => {
  const newItems = [...items.slice(0, index), item, ...items.slice(index, items.length)]
  return newItems
}

const swapElements = (array: Array<any>, from: number, to: number): Array<any> => {
  const temp = array[from]
  array[from] = array[to]
  array[to] = temp
  return array
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}

function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")"
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")"
  }
}

function dashSeparatedInput(text: string): string {
  // Split the text into words.
  const words = text.split(" ")

  // Create a new string with dashes between the words.
  const dashedText = words.join("-")

  // Return the new string.
  return dashedText
}

const toBase64 = async (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export {
  createRange,
  hexToRGB,
  swapElements,
  dashSeparatedInput,
  //findLibItem,
  insertFormObjectAt,
  toBase64,
  // fieldTypeNames,
  libraryItems,
  elementItems,
  formLibraryComponents,
  reorder,
  copy,
  move,
}
