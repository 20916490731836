import { BASE_API_URL } from "../constants/commonStrings.constant"
import { apiService } from "./api.service"

class FeatureEventService {
  async addFeatureEvent(payload: any): Promise<string> {
    const url = BASE_API_URL + "/featureEvent"
    const response = await apiService.post(
      url,
      {
        ...payload,
      },
      {
        withCredentials: false,
      },
    )

    const { data } = response

    return data
  }
}

export const featureEventService = new FeatureEventService()
