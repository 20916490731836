import type { PaletteOptions } from "@mui/material"
import { grey } from "@mui/material/colors"

const design = {
  drawerWidth: 256,
  drawerMinimizedWidth: 64,
  appBarHeight: 64,
  statusBar: 47.75,
  rightDrawerWidth: 470,
  topBarHeight: 64 + 47.75,
  bottomBarHeight: 54,
  shadow: "0px 1px 5px -2px rgba(0,0,0,0.2)",
}

const lightColors: PaletteOptions = {
  primary: {
    main: "#0c355f",
    contrastText: "rgba(255,255,255,0.87)",
  },
  secondary: {
    main: "#F7941C",
    contrastText: "rgba(255,255,255,1)",
  },
  background: {
    // secondary: '#FFFFFF',
    default: grey[100],
    paper: "#FFFFFF",
  },
}

export { lightColors, design }
