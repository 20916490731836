import { Box, Button, CircularProgress, Stack } from "@mui/material"
import React from "react"

interface Props {
  isDragging: boolean
  isSaving: boolean
  onSave: () => void
  onDelete: () => void
  onCancel: () => void
}

function MapDragEventActions({ isDragging, isSaving, onCancel, onSave }: Props) {
  return (
    <Box
      display={isDragging ? "block" : "none"}
      bgcolor={"white"}
      borderRadius={24}
      position={"absolute"}
      boxShadow={"5px 5px 10px rgba(0, 0, 0, 0.3)"}
      top={"50%"}
      left={"50%"}
      zIndex={999}
      py={0.7}
      mt={2}
      px={1}
      sx={{ transform: "translate(-50%, 100%)" }}>
      <Stack direction={"row"} gap={0.2}>
        <Button
          size="small"
          sx={{ fontSize: 14, textTransform: "capitalize", borderRadius: 24, px: 2 }}
          onClick={onSave}>
          {isSaving ? <CircularProgress size={20} /> : "Save"}
        </Button>
        <Button
          size="small"
          sx={{ fontSize: 14, textTransform: "capitalize", borderRadius: 24, px: 2 }}
          onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
    </Box>
  )
}

export default MapDragEventActions
