// src/utils/fileCache.js

import { openDB } from "idb"

const DB_NAME = "UploadDB"
const FILE_STORE_NAME = "fileData"

export async function getDB() {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(FILE_STORE_NAME)) {
        db.createObjectStore(FILE_STORE_NAME)
      }
    },
  })
}

export async function saveFileData(key: string, fileBlob: Blob) {
  try {
    const db = await getDB()
    const tx = db.transaction(FILE_STORE_NAME, "readwrite")
    await tx.store.put(fileBlob, key)
    await tx.done
  } catch (error) {
    console.error("Error saving file data to IndexedDB:", error)
    throw error
  }
}

export async function getFileData(key: string) {
  try {
    const db = await getDB()
    const tx = db.transaction(FILE_STORE_NAME, "readonly")
    const fileBlob = await tx.store.get(key)
    await tx.done
    return fileBlob
  } catch (error) {
    console.error("Error retrieving file data from IndexedDB:", error)
    throw error
  }
}

export async function deleteFileData(key: string) {
  try {
    const db = await getDB()
    const tx = db.transaction(FILE_STORE_NAME, "readwrite")
    await tx.store.delete(key)
    await tx.done
  } catch (error) {
    console.error("Error deleting file data from IndexedDB:", error)
    throw error
  }
}

// function to get all keys (blob names) in the store
export async function getAllKeys() {
  const db = await getDB()
  const tx = db.transaction(FILE_STORE_NAME, "readonly")
  const keys = await tx.store.getAllKeys()
  await tx.done
  return keys
}

// function to get the size of a blob
export async function getBlobSize(key) {
  const db = await getDB()
  const tx = db.transaction(FILE_STORE_NAME, "readonly")
  const blob = await tx.store.get(key)
  await tx.done
  return blob ? blob.size : 0
}

export async function getIndexedDBUsage() {
  const keys = await getAllKeys()
  let totalSize = 0
  const blobCount = keys.length

  for (const key of keys) {
    const size = await getBlobSize(key)
    totalSize += size
  }

  return {
    totalSize, // in bytes
    blobCount,
  }
}

export async function clearAllFileData() {
  try {
    const db = await getDB()
    const tx = db.transaction(FILE_STORE_NAME, "readwrite")
    await tx.store.clear()
    await tx.done
  } catch (error) {
    console.error("Error clearing file data from IndexedDB:", error)
    throw error
  }
}

// New function to retrieve all image blobs
export async function getAllFiles() {
  const db = await getDB()
  const tx = db.transaction(FILE_STORE_NAME, "readonly")
  const store = tx.objectStore(FILE_STORE_NAME)

  const files = []
  let cursor = await store.openCursor()
  while (cursor) {
    files.push({
      key: cursor.key,
      blob: cursor.value,
    })
    cursor = await cursor.continue()
  }
  await tx.done
  return files
}
