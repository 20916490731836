import React, { useContext, useEffect, useState } from "react"

import { Box, Stack, ThemeProvider, Typography, createTheme } from "@mui/material"

import Brand from "../../Atoms/Brand/Brand.component"
import { routes } from "../../../constants/routes.constant"
import Profile from "../Profile/Profile.component"
import type { IUser } from "../../../interfaces/user.interface"
import { NavLink } from "react-router-dom"
import * as VSCIcons from "react-icons/vsc"
import * as HI2Icons from "react-icons/hi2"
import * as HIIcons from "react-icons/hi"
import * as LIAIcons from "react-icons/lia"
import * as GoIcons from "react-icons/go"
import * as PiIcons from "react-icons/pi"

import type { Icons } from "../../../interfaces/icons.interface"
import SettingsContext from "../../../contexts/settings.context"

function Topbar() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  })

  const [user, setUser] = useState<IUser>()
  const {
    settingsState: { activeProject },
  } = useContext(SettingsContext)

  const icons: Icons = {
    ...VSCIcons,
    ...HIIcons,
    ...HI2Icons,
    ...LIAIcons,
    ...GoIcons,
    ...PiIcons,
  }

  useEffect(() => {
    const data = localStorage.getItem("user")
    if (data) {
      setUser(JSON.parse(data))
    }
  }, [])

  return (
    <ThemeProvider theme={darkTheme}>
      <Stack
        sx={{
          background: "linear-gradient(0deg, #0c355f 2%, rgba(26,47,68,1) 62%);",
          width: "100%",
          minHeight: 48,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "sticky",
          top: 0,
          zIndex: 999,
        }}>
        <Stack height="100%" px={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Box width={44}>
            <Brand to={"/"} name={"MapSource"} image={"/logo512.png"} />
          </Box>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            {activeProject &&
              activeProject.projectId &&
              routes &&
              routes
                .filter((r) => r.group === "Project")
                .map((route, index) => {
                  const Icon = icons[route.icon]
                  return (
                    <React.Fragment key={index}>
                      <NavLink to={route.path} style={{ textDecoration: "none" }}>
                        {({ isActive }) => (
                          <Stack
                            p={1}
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{ opacity: isActive ? 1 : 0.7 }}>
                            <Icon size={24} color={"white"} />
                            <Typography fontWeight={500} color={"white"} fontSize={12}>
                              {route.name}
                            </Typography>
                          </Stack>
                        )}
                      </NavLink>
                    </React.Fragment>
                  )
                })}
          </Stack>
          {user && user.firstName && user.lastName && user.email && (
            <Profile firstName={user.firstName} lastName={user.lastName} email={user.email} />
          )}
        </Stack>
      </Stack>
    </ThemeProvider>
  )
}

export default Topbar
