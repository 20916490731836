import type { ButtonProps } from "@mui/material"
import { Box, Button, IconButton, Divider, Paper, Popper, Stack, Typography, useTheme } from "@mui/material"
import React, { useRef } from "react"
import { useOnClickOutside } from "usehooks-ts"
import { design } from "../../../constants/design.constants"

interface IProps extends ButtonProps {
  onConfirm: () => void
  title: string
  message?: string
  onCancel: () => void
  stylecolor: string
  iconButton?: boolean
}

function ConfirmationButton({ onCancel, onConfirm, title, message, iconButton, disableElevation, ...props }: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? "simple-popper" : undefined
  const popperRef = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()

  // Styles for the elements
  const paperStyle = {
    borderColor: theme.palette.grey[100],
    boxShadow: design.shadow,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 3,
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const renderTriggerButton = () => {
    if (iconButton) {
      return (
        <IconButton {...props} aria-describedby={id} onClick={handleClick}>
          {props.children}
        </IconButton>
      )
    }
    return (
      <Button
        {...props}
        sx={{ borderRadius: 24, px: 2, py: 0.7, textTransform: "capitalize" }}
        aria-describedby={id}
        onClick={handleClick}>
        {props.children}
      </Button>
    )
  }

  useOnClickOutside(popperRef, () => setAnchorEl(null))
  return (
    <Box component={"div"}>
      {renderTriggerButton()}
      <Popper
        placement="bottom-end"
        disablePortal={false}
        ref={popperRef}
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 1000 }}>
        <Paper elevation={4} sx={paperStyle}>
          <Stack>
            <Stack p={2} bgcolor={"#D3312F"} sx={{ borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
              <Typography color={"white"} fontWeight={500} fontSize={16}>
                {title}
              </Typography>
            </Stack>
            <Divider />
            {message && (
              <Box px={2} py={1}>
                <Typography fontSize={14} color={theme.palette.grey[800]}>
                  {message}
                </Typography>
              </Box>
            )}
            <Stack justifyContent={"end"} direction={"row"} gap={1} p={2}>
              <Button
                size="small"
                variant="outlined"
                fullWidth
                sx={{ textTransform: "capitalize", borderRadius: 12 }}
                onClick={() => {
                  setAnchorEl(null)
                  onCancel()
                }}>
                No, Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                color="error"
                disableElevation
                fullWidth
                sx={{ textTransform: "capitalize", borderRadius: 12 }}
                onClick={() => {
                  setAnchorEl(null)
                  onConfirm()
                }}>
                Yes, Continue
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Popper>
    </Box>
  )
}

export default ConfirmationButton
