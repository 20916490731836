export const routes = [
  { path: "/project/features", name: "Features", group: "Project", icon: "PiPolygonLight" },
  { path: "/project/jobs", name: "Jobs", group: "Project", icon: "GoChecklist" },
  { path: "/project/map", name: "Map", group: "Project", icon: "HiOutlineMap" },
  { path: "/project/points", name: "Points", group: "Project", icon: "PiMapPinLine" },
  { path: "/project/reports", name: "Reports", group: "Project", icon: "HiOutlineDocumentReport" },
  { path: "/dashboard/templates", name: "Templates", group: "Settings", icon: "HiOutlineClipboardDocumentList" },
  { path: "/dashboard/projects", name: "Projects", group: "Settings", icon: "VscTools" },
  { path: "/dashboard/worlds", name: "Worlds", group: "Settings", icon: "HiGlobeAmericas" },
  { path: "/dashboard/users", name: "Users", group: "Settings", icon: "VscOrganization" },
  { path: "/permissions", name: "Permissions", group: "Settings", icon: "HiOutlineLockClosed" },
]
