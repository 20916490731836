import { Box, Button, Divider, Stack, Typography, useTheme } from "@mui/material"
import type { Feature } from "@turf/helpers"
import React, { useState } from "react"
import FeatureProperties from "./FeatureProperties"

function FeaturePropertiesSheet({
  onBack,
  onEdit,
  ...props
}: {
  feature: Feature
  onBack: () => void
  onEdit: () => void
}) {
  const [feature] = useState<Feature>(props.feature)
  const theme = useTheme()

  return (
    <Stack width={"100%"} height={"100%"} p={2} gap={1.5}>
      <Box bgcolor={"white"} borderRadius={2} height={"100%"} overflow={"hidden"}>
        <Stack width={"100%"} justifyContent={"center"} bgcolor={theme.palette.grey[100]} p={1.2}>
          <Typography fontSize={12} textAlign={"center"} color={theme.palette.grey[700]}>
            Properties
          </Typography>
        </Stack>
        <Divider />
        <Box padding={2}>
          <FeatureProperties feature={feature} />
        </Box>
      </Box>

      <Stack gap={1.5} direction={"row"}>
        <Button
          fullWidth
          variant="contained"
          onClick={onBack}
          sx={{
            textTransform: "capitalize",
            fontSize: 16,
            color: "black",
            padding: 1.5,
            fontWeight: "bold",
            bgcolor: "white",
            borderRadius: 3,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            "&:hover": {
              background: "white",
            },
          }}>
          Back
        </Button>
        <Button
          fullWidth
          onClick={onEdit}
          variant="contained"
          sx={{
            textTransform: "capitalize",
            opacity: 0,
            fontSize: 16,
            color: "black",
            padding: 1.5,
            fontWeight: "bold",
            bgcolor: "white",
            borderRadius: 3,
            width: "100%",
            height: "100%",
            "&:hover": {
              background: "white",
            },
            overflow: "hidden",
          }}>
          Edit
        </Button>
      </Stack>
    </Stack>
  )
}

export default FeaturePropertiesSheet
