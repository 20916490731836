import type { IconType } from "react-icons"
import * as VSCIcons from "react-icons/vsc"
import * as HI2Icons from "react-icons/hi2"
import * as HIIcons from "react-icons/hi"
import * as LIAIcons from "react-icons/lia"
import * as GoIcons from "react-icons/go"
import * as PiIcons from "react-icons/pi"
import * as BsIcons from "react-icons/bs"

export interface Icons {
  [key: string]: IconType
}

export const icons: Icons = {
  ...VSCIcons,
  ...HIIcons,
  ...HI2Icons,
  ...LIAIcons,
  ...GoIcons,
  ...PiIcons,
  ...BsIcons,
}
