import React, { useCallback, useMemo, useState } from "react"
import type { Theme } from "@mui/material"
import { Badge, Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import type { Feature } from "@turf/helpers"
import Lightbox from "yet-another-react-lightbox"
import { Captions, Thumbnails, Zoom } from "yet-another-react-lightbox/plugins"
import type { IFormAttachment } from "../../../interfaces"
import { ImCompass } from "react-icons/im"
import { hexToRGB } from "../../FormBuilder/utilities"

const filterProperties = (properties: any, excludedProps: string[]) => {
  return Object.fromEntries(Object.entries(properties).filter(([key]) => !excludedProps.includes(key)))
}

function FeatureProperties({ feature, excludedProps = [] }: { feature: Feature; excludedProps?: string[] }) {
  const theme = useTheme()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const [openLightBox, setOpenLightBox] = React.useState(false)
  const [activeIndex] = useState<number>(0)
  const [images, setImages] = useState<IFormAttachment[]>([])

  // Use useMemo to memoize the filtered properties
  const filteredProps = useMemo(() => {
    if (!feature.properties) return {}
    const displayProps = feature.properties.displayProps || feature.properties
    return filterProperties(displayProps, excludedProps)
  }, [feature.properties, excludedProps])

  const handleOnFeatureAttachmentClick = useCallback((attachments: IFormAttachment[]) => {
    if (attachments && attachments.length > 0) {
      setImages(attachments)
      setOpenLightBox(true)
    }
  }, [])

  const renderLightBox = () => (
    <Lightbox
      carousel={{ finite: true }}
      index={activeIndex}
      open={openLightBox}
      plugins={[Zoom, Thumbnails, Captions]}
      close={() => setOpenLightBox(false)}
      slides={images.map((image: IFormAttachment) => {
        return {
          src: image.url ? image.url : image.data ? `data:image/png;base64,${image.data}` : "",
          description: (
            <>
              <Box
                bgcolor={hexToRGB(theme.palette.grey[900], 0.5)}
                alignItems={"center"}
                width={"100%"}
                justifyContent={"space-between"}
                py={1}
                px={2}
                display={"flex"}
                flexDirection={"row"}
                position={"fixed"}
                top={0}
                left={0}>
                <Box flexDirection={"row"} display={"flex"}>
                  {image.orientation && (
                    <Box
                      style={{
                        transform: `rotate(${-(image.orientation + 45)}deg)`,
                        transition: "transform 0.5s linear", // Smooth transition for rotation
                      }}
                      sx={{ color: "white" }}>
                      <ImCompass fontSize="24" />
                    </Box>
                  )}
                  <Typography variant="body1" color={"white"} ml={2}>
                    {image.orientation ? image.orientation.toFixed(1) : "N/A"}°
                  </Typography>
                </Box>
                <Typography variant="body1" color={"white"}>
                  {image.longitude ? image.longitude.toFixed(4) + ", " : ""}{" "}
                  {image.latitude ? image.latitude.toFixed(4) : ""}
                </Typography>
              </Box>
            </>
          ),
        }
      })}
    />
  )

  if (Object.keys(filteredProps).length === 0) {
    return <Stack gap={0.5}>The feature has no display attributes</Stack>
  }

  return (
    <Stack gap={0.5}>
      {Object.entries(filteredProps).map(([key, value], index) => (
        <Stack direction={"row"} gap={2} key={index} alignItems={"center"}>
          <Typography
            minWidth={100}
            fontStyle={"italic"}
            fontSize={isDesktop ? 10 : 12}
            color={theme.palette.grey[600]}>
            {key}:
          </Typography>
          {(value as any) && (value as any).length > 0 && (value as any)[0] && (value as any)[0].url ? (
            <Stack direction={"row"} flexWrap={"wrap"} gap={1} style={{ transform: "translateY(-10px)" }}>
              <Button onClick={() => handleOnFeatureAttachmentClick(value as IFormAttachment[])}>
                <Badge
                  badgeContent={Array.from(value as any).length}
                  max={999}
                  slotProps={{
                    badge: {
                      style: { fontSize: 10, padding: 1 },
                    },
                  }}
                  color="error">
                  <Box width={70} height={70} position={"relative"}>
                    <Box width={70} height={70}>
                      <img
                        height={"100%"}
                        width={"100%"}
                        src={Array.from(value as IFormAttachment[])[0].url}
                        alt={Array.from(value as IFormAttachment[])[0].name}
                      />
                      {Array.from(value as IFormAttachment[]).length > 1 && (
                        <Box width={70} height={70} position={"absolute"} top={7} border={"solid 2px white"} right={7}>
                          <img
                            height={"100%"}
                            width={"100%"}
                            src={Array.from(value as IFormAttachment[])[1].url}
                            alt={Array.from(value as IFormAttachment[])[1].name}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Badge>
              </Button>
            </Stack>
          ) : (
            <Typography alignSelf={"self-start"} fontSize={isDesktop ? 12 : 14} textAlign={"left"}>
              {value as string}
            </Typography>
          )}
        </Stack>
      ))}
      {renderLightBox()}
    </Stack>
  )
}

export default FeatureProperties
